import { makeStyles } from 'tss-react/mui'
import { Box, Grid, Typography } from '@mui/material'
import useCameraSelection from '~/hooks/useCameraSelection'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import BaseWebcam from 'react-webcam'
import { LoadingButton } from '~/components/Button'
import PropTypes from 'prop-types'

const useStyles = makeStyles()(() => ({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
}))

const Webcam = ({
    selectedFiles,
    setSelectedFiles,
    imageLimit,
    videoWidth = 920,
    screenshotWidth = 1300,
    defaultAddToDispatch = false,
}) => {
    const { classes } = useStyles()
    const { cameraSelection, selectedCamera } = useCameraSelection()
    const [availableCamera, setAvailableCamera] = useState(true)

    const webcamRef = useRef()

    const capture = useCallback(() => {
        const files = [...selectedFiles]
        if (files.length >= imageLimit) {
            return
        }

        if (
            webcamRef.current.getScreenshot({
                width: screenshotWidth,
                height: (screenshotWidth / 16) * 9,
            })
        ) {
            files.push({
                image: webcamRef.current.getScreenshot(),
                checked: defaultAddToDispatch,
            })
        }
        setSelectedFiles(files)
    }, [
        selectedFiles,
        imageLimit,
        screenshotWidth,
        setSelectedFiles,
        defaultAddToDispatch,
    ])

    const videoConstraints = useMemo(() => {
        return {
            audio: false,
            width: videoWidth,
            height: (videoWidth / 16) * 9,
            deviceId: selectedCamera,
        }
    }, [selectedCamera, videoWidth])

    useEffect(() => {
        if (selectedCamera) {
            navigator.mediaDevices
                .getUserMedia({ video: { deviceId: selectedCamera } })
                .then(() => {
                    setAvailableCamera(true)
                })
                .catch(() => {
                    setAvailableCamera(false)
                })
        }
    }, [selectedCamera, setAvailableCamera])

    return (
        <>
            <Grid container direction='column' className={classes.container}>
                <Grid item>
                    {availableCamera ? (
                        <BaseWebcam
                            ref={webcamRef}
                            width={videoWidth}
                            height={(videoWidth / 16) * 9}
                            videoConstraints={videoConstraints}
                            screenshotFormat='image/jpeg'
                        />
                    ) : (
                        <Box
                            width={videoWidth}
                            height={(videoWidth / 16) * 9}
                            sx={{ background: 'black' }}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    sx={{
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr auto 1fr',
                            width: '100%',
                        }}
                    >
                        <Box />
                        <Box pt={1}>
                            <LoadingButton
                                onClick={capture}
                                label='Capture photo'
                            />
                        </Box>

                        <Grid
                            container
                            direction='row'
                            justifyContent='flex-end'
                            alignItems='center'
                            paddingLeft={2}
                        >
                            <Grid item>
                                <Typography>Select camera:</Typography>
                            </Grid>
                            <Grid item>{cameraSelection}</Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

Webcam.propTypes = {
    selectedFiles: PropTypes.array,
    setSelectedFiles: PropTypes.func,
    imageLimit: PropTypes.number,
    videoWidth: PropTypes.number,
    screenshotWidth: PropTypes.number,
    defaultAddToDispatch: PropTypes.bool,
}

export default Webcam
