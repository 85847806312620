export const ActionLogsHeadCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'id',
        orderable: false,
    },
    {
        id: 'user_id',
        numeric: true,
        disablePadding: false,
        label: 'userId',
        orderable: false,
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'action',
        orderable: false,
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'description',
        orderable: false,
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'createdAt',
        orderable: true,
    },
]
