import {
    Chip,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useActivityLogQuery } from './query'
import useTable from '~/hooks/useTable'
import TableHeader from '~/components/TableHeader'
import PaginationTable from '~/components/PaginationTable'
import { ActionLogsHeadCells } from './ActionLogsHeadCells'
import TableLoading from '~/components/Table/TableLoading'
import DataNotFound from '~/components/DataNotFound'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import ReferencedEntities from '~/components/ReferencedEntities'
import { ADMINISTRATOR, ADMINISTRATOR_ACTIVITY_LOGS } from '~/constants/Routes'
import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import PageName from '~/components/PageName'
import ActivityLogTableFilter from './ActivityLogTableFilter'
import { getActionColor } from '~/utils/helpers'
import { setFilterObject } from '~/store/filter/slice'
import { useDispatch } from 'react-redux'

const ActivityLogTableBody = ({ isLoading, logs }) => {
    if (isLoading) {
        return <TableLoading colSpan={ActionLogsHeadCells.length} />
    }
    return (
        <TableBody>
            {logs?.length === 0 && (
                <DataNotFound colSpan={ActionLogsHeadCells.length} />
            )}
            {logs?.length > 0 &&
                logs?.map((row) => {
                    const chipcolor = getActionColor(
                        row.properties?.action.toString().toUpperCase(),
                    )

                    return (
                        <TableRow key={row.id}>
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={'activity-log-id'}
                                dataIdentifier={row.id}
                            />
                            <ReferencedEntities
                                isTableCell={true}
                                dataType={
                                    row?.causer_type === 'App\\Models\\User'
                                        ? 'users-id'
                                        : 'service-account-id'
                                }
                                dataIdentifier={row.user_id}
                                dataDisplay={row.name}
                                sx={{
                                    maxWidth: '100px',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                }}
                            />

                            <TableCell>
                                <Chip
                                    label={row.properties?.action
                                        .toString()
                                        .toUpperCase()}
                                    color={chipcolor}
                                    size='small'
                                />
                            </TableCell>
                            <TableCell>
                                {row?.subject_type?.replace(
                                    'App\\Models\\',
                                    '',
                                )}{' '}
                                {' - '}
                                {row?.subject_id}
                            </TableCell>
                            <TableCell>
                                <DateTimeLocale datetime={row?.created_at} />
                            </TableCell>
                        </TableRow>
                    )
                })}
        </TableBody>
    )
}

ActivityLogTableBody.propTypes = {
    logs: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
}

const localization = 'pages.administrator.activities.'
const ActivityLogs = () => {
    const BreadComponents = [
        { route: ADMINISTRATOR, title: 'Administrator' },
        {
            route: ADMINISTRATOR_ACTIVITY_LOGS,
            title: 'activityLogs',
        },
    ]
    const dispatch = useDispatch()

    const [action, setAction] = useState('')
    const [user, setUser] = useState('')
    const [type, setType] = useState('')
    const [rangeDate, setRangeDate] = useState([null, null])
    const { t } = useTranslation()

    const {
        results,
        data,
        search,
        isLoading,
        order,
        page,
        orderBy,
        searchHandler,
        setPage,
        sortHandler,
        changePageHandler,
        onEnterSearch,
    } = useTable({
        query: useActivityLogQuery,
        initialStates: {
            order: 'desc',
            page: 1,
            orderBy: 'created_at',
            filter: '',
        },
        queryAttributes: {
            action: action ?? '',
            from: rangeDate[0],
            to: rangeDate[1],
            userValue: user,
            userType: type ?? null,
        },
    })
    const refValue = useRef({
        action: '',
        user: '',
        type: '',
        rangeDate: [null, null],
    })

    useEffect(() => {
        if (
            action !== refValue.current.action ||
            type !== refValue.current.type ||
            user !== refValue.current.user ||
            (rangeDate[0] !== refValue.current.rangeDate[0] &&
                rangeDate[1] !== refValue.current.rangeDate[1])
        ) {
            setPage(1)
            refValue.current.action = action
            refValue.current.user = user
            refValue.current.type = type
            refValue.current.rangeDate[0] = rangeDate[0]
            refValue.current.rangeDate[1] = rangeDate[1]
        }
    }, [action, rangeDate, setPage, type, user])

    const handleChangeType = (value) => {
        dispatch(
            setFilterObject({
                p: 1,
                filter: {
                    name: 'User',
                    value: 'All',
                },
            }),
        )
        setUser('')
        setType(value)
    }
    return (
        <>
            <BreadCrumb breadcrumbs={BreadComponents} />

            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_ACTIVITY_LOGS}
            >
                <div>
                    <PageName name={t(`${localization}pageName`)} />
                </div>
            </ComponentTooltip>

            <Paper elevation={1}>
                <ActivityLogTableFilter
                    search={search}
                    searchHandler={searchHandler}
                    onEnterSearch={onEnterSearch}
                    action={action}
                    setAction={setAction}
                    user={user}
                    setUser={setUser}
                    rangeDate={rangeDate}
                    setRangeDate={setRangeDate}
                    type={type}
                    setType={handleChangeType}
                />

                <Divider />

                <TableContainer>
                    <Table>
                        <TableHeader
                            headCells={ActionLogsHeadCells}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                            localization={localization}
                        />
                        <ActivityLogTableBody
                            isLoading={isLoading}
                            logs={results}
                        />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    size='medium'
                    handleChangePage={changePageHandler}
                />
            </Paper>
        </>
    )
}

export default ActivityLogs
