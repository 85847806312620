import {
    Divider,
    TableBody as MuiTableBody,
    Paper,
    Table,
    TableContainer,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import { UserHeadCells } from '~/components/HeaderTable/UserHeadCells'
import PaginationTable from '~/components/PaginationTable'
import TableLoading from '~/components/Table/TableLoading'
import TableHeader from '~/components/TableHeader'
import Row from '~/components/TableRow'
import { useSnackbar } from '~/hooks/useSnackbar'
import useTable from '~/hooks/useTable'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectMessageDeleteUser } from '~/store/user/selector'
import { setMessageDeleteUser } from '~/store/user/slice'
import UserListToolbar from './UserListToolbar'
import UserTableFilters from './UserTableFilters'
import { useUsersQuery } from './query'
import PropTypes from 'prop-types'

const localization = 'pages.user.'

const UserTableBody = ({ isLoading, users }) => {
    if (isLoading) {
        return <TableLoading colSpan={UserHeadCells.length} />
    }

    return (
        <MuiTableBody>
            {users.length > 0 ? (
                users.map((user) => {
                    return (
                        <Row
                            row={user}
                            columnsConfig={UserHeadCells}
                            key={user.id}
                        />
                    )
                })
            ) : (
                <DataNotFound colSpan={UserHeadCells.length} />
            )}
        </MuiTableBody>
    )
}

UserTableBody.propTypes = {
    isLoading: PropTypes.bool,
    users: PropTypes.arrayOf(PropTypes.object),
}

const UsersTable = () => {
    const dispatch = useDispatch()
    const messageDelUser = useSelector(selectMessageDeleteUser)
    const [state, setState] = useState('')
    const [role, setRole] = useState('')
    const { t } = useTranslation()
    const { showSnackbar, snackbar } = useSnackbar()
    const {
        results: users,
        data,
        isLoading,
        page,
        order,
        orderBy,
        search,
        setPage,
        changePageHandler,
        searchHandler,
        sortHandler,
        onEnterSearch,
    } = useTable({
        query: useUsersQuery,
        queryAttributes: {
            role,
            state,
        },
    })

    const refValue = useRef({
        state: '',
        role: '',
    })

    useEffect(() => {
        if (
            state !== refValue.current.state ||
            role !== refValue.current.role
        ) {
            setPage(1)
            refValue.current.state = state
            refValue.current.role = role
        }
    }, [role, setPage, state])

    useEffect(() => {
        if (messageDelUser) {
            showSnackbar(t('message.deleteUser'), 'success')
            dispatch(setMessageDeleteUser(null))
        }
    }, [dispatch, messageDelUser, showSnackbar, t])

    return (
        <>
            <UserListToolbar
                payload={{ state, role, order, orderBy, search }}
            />

            <Paper sx={{ width: '100%', mb: 2 }}>
                {snackbar}
                <UserTableFilters
                    {...{
                        search,
                        searchHandler,
                        role,
                        setRole,
                        state,
                        setState,
                        onEnterSearch,
                    }}
                />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby='tableTitle'
                        size='medium'
                    >
                        <TableHeader
                            headCells={UserHeadCells}
                            localization={localization}
                            onRequestSort={sortHandler}
                            order={order}
                            orderBy={orderBy}
                        />
                        <UserTableBody isLoading={isLoading} users={users} />
                    </Table>
                </TableContainer>
                <PaginationTable
                    data={data}
                    page={page}
                    handleChangePage={changePageHandler}
                    size='medium'
                />
            </Paper>
        </>
    )
}
export default UsersTable
