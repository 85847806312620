import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableLoading from '~/components/Table/TableLoading'
import PartCodeListComponent from '~/components/partCodes/PartCodeListComponent'
import useTable from '~/hooks/useTable'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectDispatchSerial } from '~/store/dispatch/selector'
import {
    selectSelectedPartCodes,
    selectSelectedPartCodeTypes,
} from '~/store/partcode/selector'
import { usePartCodeQuery } from '../../query'
import PartCodeListSelectorComponent from './PartCodeListSelectorComponent'
import PropTypes from 'prop-types'

const PartCodeList = ({ serial }) => {
    const [filteredCode, setFilteredCode] = useState('')
    const selectedPartCodeTypes = useSelector(selectSelectedPartCodeTypes)
    const dispatchSerial = useSelector(selectDispatchSerial)
    const addedPartCode = useSelector(selectSelectedPartCodes)

    const { data, setPage, isLoading, page, changePageHandler } = useTable({
        query: usePartCodeQuery,
        initialStates: {
            page: 1,
            order: null,
            orderBy: null,
            search: null,
        },
        queryAttributes: {
            code: filteredCode,
            types: selectedPartCodeTypes,
            serial: serial ?? dispatchSerial,
        },
    })

    const refValue = useRef({
        code: '',
        types: '',
    })

    const renderedPartCodes = useMemo(() => {
        if (isLoading || !data?.data) return []
        return data.data.map((partCode) => {
            const addedPart = addedPartCode.find(
                (el) => el.code === partCode.code,
            )
            let added = false
            let qty = 1
            if (addedPart !== undefined) {
                added = true
                qty = addedPart.qty
            }
            return {
                code: partCode.code,
                name: partCode.name,
                added,
                qty,
                type: partCode.type,
                serializable: partCode.serializable,
                causes: [{ id: '#', tag: '#' }],
            }
        })
    }, [data, isLoading, addedPartCode])
    useEffect(() => {
        if (
            filteredCode !== refValue.current.filteredCode ||
            selectedPartCodeTypes !== refValue.current.selectedPartCodeTypes
        ) {
            setPage(1)
            refValue.current.filteredCode = filteredCode
            refValue.current.selectedPartCodeTypes = selectedPartCodeTypes
        }
    }, [filteredCode, selectedPartCodeTypes, setPage])

    return (
        <>
            <PartCodeListComponent
                {...{
                    filteredCode,
                    setFilteredCode,
                    data,
                    page,
                    changePage: changePageHandler,
                }}
            >
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Part code</TableCell>
                            <TableCell>Part name</TableCell>
                            <TableCell align='center'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableLoading colSpan={3} />
                    ) : (
                        <TableBody>
                            {renderedPartCodes.map((partCode) => (
                                <PartCodeListSelectorComponent
                                    key={partCode.code}
                                    partCode={partCode}
                                />
                            ))}
                        </TableBody>
                    )}
                </Table>
            </PartCodeListComponent>
        </>
    )
}

PartCodeList.propTypes = { serial: PropTypes.string }

export default PartCodeList
