export const LOCALIZATION = 'pages.diagnostic.'

export const COSMETIC_DAMAGE = 'cosmetic_damage'
export const ACCESSORY_INCLUDED = 'accessory_included'
export const POWERS_ON = 'powers_on'
export const LIQUID_DAMAGE = 'liquid_damage'
export const MALICIOUS_DAMAGE = 'malicious_damage'
export const LIQUID_DAMAGE_TOTAL_LOSS = 'liquid_damage_total_loss'
export const ACCIDENTIAL_DAMAGE = 'accidental_damage'
export const SOFTWARE_ISSUE = 'software_issue'
export const COMPLAINT_FOUND = 'complaint_found'

export const START_DIAGNOSTICS_STATE = 'START_DIAGNOSTICS'

export const CHECKBOX_DIAGNOSTICS = [
    COSMETIC_DAMAGE,
    ACCESSORY_INCLUDED,
    POWERS_ON,
    SOFTWARE_ISSUE,
    LIQUID_DAMAGE,
    LIQUID_DAMAGE_TOTAL_LOSS,
    ACCIDENTIAL_DAMAGE,
    MALICIOUS_DAMAGE,
    COMPLAINT_FOUND,
]

export const STEPS_LENGTH = 3
