import axiosClient from '~/api/base'

class ActivityLogsApi {
    getActivityLogs = (params) =>
        axiosClient.get('api/activity-logs', { params })

    getUserActivityLogs = (params) =>
        axiosClient.get(`api/activity-logs-by-users`, { params })

    getActivityLogsDetails = (id) => axiosClient.get(`api/activity-logs/${id}`)
}
export default new ActivityLogsApi()
