import { Chip, Grid, Paper, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import BreadCrumb from '~/components/BreadCrumb'
import { ADMINISTRATOR, ADMINISTRATOR_ACTIVITY_LOGS } from '~/constants/Routes'
import { useActivityLogDetailByIdQuery } from '../query'
import { useTranslation } from 'react-i18next'
import LogInformation from './LogInformation'
import DataChangeTable from './DataChangeTable'
import { useMemo } from 'react'
import { getActionColor } from '~/utils/helpers'
import ReferencedEntities from '~/components/ReferencedEntities'

const localization = 'pages.administrator.activities.'

const ActivityLogsDetails = () => {
    const { id } = useParams()
    const { t } = useTranslation()

    const breadCrumbConfig = [
        { route: ADMINISTRATOR, title: 'Administrator' },
        {
            route: ADMINISTRATOR_ACTIVITY_LOGS,
            title: 'activityLogs',
        },
        { page: 'detail', title: id },
    ]
    const { data, isLoading } = useActivityLogDetailByIdQuery({ id })

    const logInfors = [
        {
            key: t(`${localization}id`),
            value: data?.data?.id,
        },
        {
            key: t(`${localization}action`),
            value: (
                <>
                    <Chip
                        label={data?.data?.causer_id ? 'API' : 'EVENT'}
                        color={data?.data?.causer_id ? 'info' : 'secondary'}
                        size='small'
                        sx={{ marginRight: 1 }}
                    />
                    <Chip
                        label={data?.data?.properties?.action
                            .toString()
                            .toUpperCase()}
                        color={getActionColor(
                            data?.data?.properties?.action
                                .toString()
                                .toUpperCase(),
                        )}
                        size='small'
                    />
                </>
            ),
        },
        {
            key: t(`${localization}description`),
            value: `${
                data?.data?.description
            } ${data?.data?.subject_type?.replace('App\\Models\\', '')} ${
                data?.data?.subject_id
            }`,
        },
        {
            key: t(`${localization}model`),
            value: data?.data?.subject_type ?? '',
        },
        {
            key: t(`${localization}user`),
            value: (
                <ReferencedEntities
                    isTableCell={false}
                    dataType={
                        data?.data?.causer_type === 'App\\Models\\User'
                            ? 'users-id'
                            : 'service-account-id'
                    }
                    dataIdentifier={data?.data?.user_id}
                    dataDisplay={`${data?.data?.name} - ${data?.data?.user_id}`}
                />
            ),
        },
        {
            key: t(`${localization}createdAt`),
            value: data?.data?.created_at,
        },
    ]

    const dataChanges = useMemo(() => {
        const dataChanges = []
        if (data?.data?.properties?.new_data) {
            for (const [key, value] of Object.entries(
                data?.data?.properties?.new_data,
            )) {
                dataChanges.push({
                    key,
                    new: value,
                    original:
                        data?.data?.properties?.original_data[`${key}`] ?? '',
                })
            }
        } else if (data?.data?.properties?.original_data) {
            for (const [key, value] of Object.entries(
                data?.data?.properties?.original_data,
            )) {
                dataChanges.push({
                    key,
                    new: value,
                    original: '',
                })
            }
        }

        return dataChanges
    }, [
        data?.data?.properties?.new_data,
        data?.data?.properties?.original_data,
    ])

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            py: 1,
                            px: 2,
                            height: '100%',
                        }}
                    >
                        <Typography variant='h6' gutterBottom>
                            {t(`${localization}activityLog`)} {id}
                        </Typography>

                        <LogInformation
                            logInfors={logInfors}
                            isLoading={isLoading}
                        />
                    </Paper>
                </Grid>

                {data?.data?.properties?.action !== 'Impersonated' && (
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                py: 1,
                                px: 2,
                                height: '100%',
                            }}
                        >
                            <Typography variant='h6' gutterBottom>
                                {t(`${localization}dataChanged`)}
                            </Typography>

                            <DataChangeTable
                                data={dataChanges}
                                isContainNewData={
                                    !!data?.data?.properties?.new_data
                                }
                            />
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </>
    )
}

export default ActivityLogsDetails
