import ActivityLogsApi from '~/api/activityLogsApi'
import { useQuery } from 'react-query'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'
import { useDialogContext } from '~/components/providers/StyledDialogContext'

export const useActivityLogQuery = (params) => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['activity_logs', params],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await ActivityLogsApi.getActivityLogs(p)
            return { data: data?.data, total: data?.meta?.total }
        },
        {
            onError: (e) => {
                const message = parseApiErrorMessage(e)
                openSnackbar({
                    message,
                    type: 'error',
                })
            },
        },
    )
}

export const useActivityLogDetailByIdQuery = ({ id }) => {
    return useQuery(
        ['activity_logs_detail', id],
        async () => {
            const { data } = await ActivityLogsApi.getActivityLogsDetails(id)
            return { data: data?.data }
        },
        {
            retry: false,
            enabled: !!id,
        },
    )
}

export const useActivityLogByUser = (params) => {
    const { openSnackbar } = useDialogContext()
    return useQuery(
        ['activity_logs_users'],
        async () => {
            const p = removeEmptyValue(params)
            const { data } = await ActivityLogsApi.getUserActivityLogs(p)
            return data
        },
        {
            onError: (e) => {
                const message = parseApiErrorMessage(e)
                openSnackbar({
                    message,
                    type: 'error',
                })
            },
        },
    )
}
