/* eslint-disable react/no-unescaped-entities */
import AddIcon from '@mui/icons-material/Add'
import HelpIcon from '@mui/icons-material/Help'
import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import BreadCrumb from '~/components/BreadCrumb'
import ErrorMessageInput from '~/components/ErrorMessageInput'
import ListToolbarWrapper from '~/components/ListToolbarWrapper'
import PageName from '~/components/PageName'
import StyledModal from '~/components/StyledModal'
import ToolBarButton from '~/components/ToolBarButton'
import { ISSUE_OVERVIEW } from '~/constants/Routes'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
    clearSelectedPartCodeTypesForDiagnostic,
    clearShowedPartCodeForDiagnostic,
} from '~/store/partcode/slice'
import Search from '../../../components/Search/Search'
import { useStartTriageIssueMutation } from '../mutation'
import TicketTable from './TicketTable'
import { useLoginUserSwitchActiveTeamMutation } from '~/pages/UserDetail/mutate'
import { ChangeCircle } from '@mui/icons-material'

const breadCrumbConfig = [
    { route: ISSUE_OVERVIEW, title: 'Tickets' },
    { route: '#', title: 'Overview' },
]

const TicketOverview = () => {
    const localization = `pages.issues.overview.`
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(null)
    const [targetTeam, setTargetTeam] = useState(null)
    const [deviceSerial, setDeviceSerial] = useState(null)

    const { mutate: scanSerial, isLoading: isScanning } =
        useStartTriageIssueMutation({ setError, setTargetTeam })

    const handleEnterSearch = (event) => {
        if (event.key === 'Enter') {
            setDeviceSerial(event.target.value)
            scanSerial(event.target.value)
        }
    }

    const handleCloseModal = () => {
        setOpen(false)
        setError(null)
        setTargetTeam(null)
    }

    const handleAddDiagnostic = () => {
        setOpen(true)
        dispatch(clearSelectedPartCodeTypesForDiagnostic())
        dispatch(clearShowedPartCodeForDiagnostic())
    }

    const scanSerialHelper = useMemo(() => {
        return (
            <>
                <Typography>
                    To add the diagnostic, ensure the following condition:
                </Typography>
                <Typography variant='body2'>
                    - The ticket state for device is "NEW".
                </Typography>
            </>
        )
    }, [])

    const { mutate: activeTeam } = useLoginUserSwitchActiveTeamMutation()
    const handleSwitchTeam = () => {
        activeTeam(
            { team_id: targetTeam?.id },
            {
                onSettled: () => {
                    setTargetTeam(null)
                    setError(null)
                },
                onSuccess: () => {
                    scanSerial(deviceSerial)
                },
            },
        )
    }

    return (
        <>
            <BreadCrumb breadcrumbs={breadCrumbConfig} />
            <PageName name={'Tickets'} />
            <StyledModal
                open={open}
                handleClose={handleCloseModal}
                stackStyles={{ width: 'auto' }}
            >
                <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Grid item>
                        <Search
                            label={t(`${localization}scan_serial`)}
                            autoFocus
                            onKeyPress={handleEnterSearch}
                        />
                        {isScanning && (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        )}
                    </Grid>
                    <Grid item>
                        <Tooltip title={scanSerialHelper} placement='top-end'>
                            <IconButton>
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {error && <ErrorMessageInput>{error}</ErrorMessageInput>}
                    {targetTeam && (
                        <ToolBarButton
                            icon={<ChangeCircle />}
                            name={
                                t(`${localization}changeTeam`) +
                                ' ' +
                                targetTeam?.name
                            }
                            handleAction={handleSwitchTeam}
                        />
                    )}
                </Grid>
            </StyledModal>
            <ListToolbarWrapper>
                <ToolBarButton
                    icon={<AddIcon />}
                    name={t(`${localization}start_triage`)}
                    handleAction={handleAddDiagnostic}
                />
            </ListToolbarWrapper>
            <TicketTable />
        </>
    )
}
export default TicketOverview
