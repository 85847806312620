import { FormControl, Grid } from '@mui/material'
import AutoCompleteInput from '~/components/FormInput/AutoCompleteInput'
import CheckboxesField from '~/components/FormInput/CheckboxesField'
import arrayMutators from 'final-form-arrays'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectShowedPartCodeForDiagnostic } from '~/store/partcode/selector'
import { pushShowPartCodesForDiagnostic } from '~/store/partcode/slice'
import SectionTitle from './components/SectionTitle'
import { LOCALIZATION } from './constants'
import { useRootCausesQuery } from './query'
import { useIssueQuery } from '../Issue/query'
import _ from 'lodash'
import PropTypes from 'prop-types'
const locale = localStorage.getItem('LOCALE')

const RootCauses = ({
    values: parentFormValues,
    setGeneralFormValue,
    finishDiagnostic,
    fetchedCauseList,
    setClickedCause = () => {},
    form,
}) => {
    const { issueId, diagnosticId: diagnosticParam } = useParams()
    const { data, isSuccess: isGettingIssueSuccess } = useIssueQuery(issueId)
    const { t } = useTranslation()
    const [searchCauseList, setSearchCauseList] = useState([])
    const [currentCauseList, setCurrentCauseList] = useState([])
    const finishedCauses = finishDiagnostic
        ? parentFormValues?.issue?.causes.map((cause) => ({
              ...cause,
              name: cause?.name?.en,
              value: true,
          }))
        : []

    const diagnosticsId = useMemo(() => {
        if (diagnosticParam) {
            return diagnosticParam
        }
        if (isGettingIssueSuccess) {
            return data?.data?.diagnostic?.id
        }
    }, [diagnosticParam, isGettingIssueSuccess, data?.data?.diagnostic?.id])

    const params = useMemo(() => {
        if (finishDiagnostic) {
            return { saved: true }
        }
        const isComplaintNotFound =
            parentFormValues?.complaint_found === false ||
            parentFormValues?.complaint_found === 0 ||
            parentFormValues?.complaint_found === 'false'

        return isComplaintNotFound ? { no_part_only: true } : {}
    }, [parentFormValues, finishDiagnostic])

    const { data: fetchedCauses, isSuccess } = useRootCausesQuery(
        diagnosticsId,
        { locale, ...params },
    )

    const prevCauses = useRef(currentCauseList)

    const showingPartCode = useSelector(selectShowedPartCodeForDiagnostic)
    const showingPartCodeRef = useRef(showingPartCode ?? [])

    useEffect(() => {
        showingPartCodeRef.current = showingPartCode
    }, [showingPartCode])

    useEffect(() => {
        const newArr = prevCauses.current.map((item) => {
            if (_.isEmpty(item.part_codes)) {
                return item
            }
            item.value = false
            showingPartCodeRef.current.forEach((partCode) => {
                partCode.causes.forEach((cause) => {
                    if (cause.tag === item.tid) {
                        item.value = true
                    }
                })
            })
            return item
        })
        setCurrentCauseList(newArr)
        prevCauses.current = newArr
        setClickedCause({
            causeValue: false,
        })
    }, [
        setClickedCause,
        showingPartCodeRef.current.length,
        prevCauses.current.length,
    ])

    const dispatch = useDispatch()

    const initCauseCheckBoxList = useCallback(
        (initCausesList) => {
            // get Fetch Id list of causes from DB
            const fetchedIdCauseList = []
            const initSearchCausesList = fetchedCauses?.slice(5)

            fetchedCauseList.forEach((cause) => {
                return fetchedIdCauseList.push(cause.id)
            })

            // add value true/false to show checked in checkbox button
            initCausesList.map((cause) => {
                return (cause.value = fetchedIdCauseList.includes(cause.id))
            })

            // add value true/false to show checked in checkbox button
            const selectedCauseInSearchArea = initSearchCausesList.filter((c) =>
                fetchedIdCauseList.includes(c.id),
            )

            // add these causes into init causeList
            selectedCauseInSearchArea.map((x) => (x.value = true))

            selectedCauseInSearchArea.forEach((x) => initCausesList.push(x))

            setCurrentCauseList(initCausesList)
            prevCauses.current = initCausesList

            return initCausesList
        },
        [fetchedCauseList, fetchedCauses],
    )

    const initCauseListSearch = useCallback(
        (currentCauseListCheckBox) => {
            const causeIdList = []
            currentCauseListCheckBox.forEach((x) => causeIdList.push(x.id))

            const currentSearchList = fetchedCauses
                ?.filter((s) => !causeIdList.includes(s.id))
                .map((obj) => {
                    return { ...obj, value: obj }
                })

            setSearchCauseList(currentSearchList)
        },
        [fetchedCauses],
    )
    useEffect(() => {
        if (isSuccess) {
            const initCausesList = fetchedCauses?.slice(0, 5)

            if (!fetchedCauseList) {
                setCurrentCauseList(initCausesList)
                prevCauses.current = initCausesList

                const searchList = initCausesList.map((obj) => {
                    return { ...obj, value: obj }
                })

                setSearchCauseList(searchList)
            } else {
                const currentCauseListCheckBox =
                    initCauseCheckBoxList(initCausesList)

                initCauseListSearch(currentCauseListCheckBox)
            }
        }
    }, [
        fetchedCauseList,
        fetchedCauses,
        initCauseCheckBoxList,
        initCauseListSearch,
        isSuccess,
    ])

    const updateCauseList = useCallback(
        (searchCause) => {
            const causes = [...currentCauseList, searchCause]
            prevCauses.current = causes

            setCurrentCauseList(causes)

            const causesSearchNewList = searchCauseList.filter(
                (cause) => cause.id !== searchCause.id,
            )
            setSearchCauseList(causesSearchNewList)
        },
        [currentCauseList, searchCauseList],
    )

    const handleUpdateCauseList = (causes) => {
        const clickedCause = causes.find((cause) => {
            const itemY = prevCauses.current.find(
                (yItem) => yItem.id === cause.id,
            )
            return itemY && cause.value !== itemY.value
        })
        setClickedCause({
            causeId: clickedCause.id,
            causeTag: {
                id: clickedCause.id,
                tag: clickedCause.tid,
            },
            causeValue: clickedCause.value,
        })

        // case: uncheck cause
        // 1. remove cause tag in part code list
        // 2. if no has cause tag for part code, remove them from showing part codes.
        // if part code removed, unset selectedPartCode action
        if (!clickedCause.value) {
            const currentShowingPartCodes = [...showingPartCode]

            // filter and get part codes with cause has value true
            const detachUncheckCausePartCodes = currentShowingPartCodes.map(
                (item) => {
                    const causes = item.causes.filter(
                        (causeItem) => causeItem.id !== clickedCause.id,
                    )
                    return { ...item, causes }
                },
            )

            // remove part code has cause = [], mean that no has causes and no manual selected.
            const hasCausePartCodes = detachUncheckCausePartCodes.filter(
                (partCode) => partCode.causes.length !== 0,
            )
            dispatch(pushShowPartCodesForDiagnostic(hasCausePartCodes))
        }

        setCurrentCauseList(causes)
        prevCauses.current = causes
    }

    const onSubmit = async () => {}

    const filterOptions = (options, state) => {
        const inputValue = state.inputValue.toLowerCase()
        return options.filter((option) => {
            const partCodes = option.part_codes.map((pc) =>
                pc.code.toLowerCase(),
            )
            return (
                option.name.toLowerCase().includes(inputValue) ||
                partCodes.includes(inputValue)
            )
        })
    }

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{
                causes: finishDiagnostic ? finishedCauses : currentCauseList,
            }}
            mutators={{
                ...arrayMutators,
            }}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <FormSpy
                        subscription={{ values: true, dirtyFields: true }}
                        onChange={(ev) => {
                            if (
                                ev.dirtyFields &&
                                Object.prototype.hasOwnProperty.call(
                                    ev.dirtyFields,
                                    'causes',
                                )
                            ) {
                                handleUpdateCauseList(ev.values.causes)
                                form.change('causes', ev.values.causes)
                                setGeneralFormValue({
                                    ...parentFormValues,
                                    causes: ev.values.causes,
                                })
                            }
                        }}
                    />
                    <Grid container>
                        <SectionTitle
                            title={t(LOCALIZATION + `diagnosticRootCause`)}
                        />
                        <Grid container direction='row-reverse'>
                            <Grid item xs={12} md={4}>
                                <FormControl sx={{ width: '100%', px: 4 }}>
                                    <Field
                                        disabled={finishDiagnostic}
                                        name='cause_id'
                                        component={AutoCompleteInput}
                                        items={searchCauseList}
                                        onUpdate={updateCauseList}
                                        filterOptions={filterOptions}
                                        noValue
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container>
                                    <CheckboxesField
                                        localization={LOCALIZATION}
                                        name={`causes`}
                                        label={false}
                                        disabled={finishDiagnostic}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        />
    )
}

RootCauses.propTypes = {
    values: PropTypes.object,
    setGeneralFormValue: PropTypes.func,
    finishDiagnostic: PropTypes.bool,
    fetchedCauseList: PropTypes.any,
    setSelectedCauses: PropTypes.func,
    setClickedCause: PropTypes.func,
    form: PropTypes.object,
}

export default RootCauses
