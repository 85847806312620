import { Box, Typography } from '@mui/material'
import ImageList from '~/components/Image/ImageList'
import Loading from '~/components/Loading'
import { usePictureDispatchQuery } from '~/pages/Ticket/query'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const DispatchAttachments = ({ ticketId }) => {
    const localization = 'pages.dispatches.dispatch.detail'
    const { t } = useTranslation('translation', { keyPrefix: localization })
    const { data, isLoading } = usePictureDispatchQuery(ticketId)

    const currentImageList = useMemo(() => {
        return (
            data?.data.map((v) => {
                return {
                    src: v.preview_url,
                    id: v.id,
                    title: t('dispatchAttachmentsTitle'),
                    created_at: v.created_at,
                }
            }) || []
        )
    }, [data?.data, t])

    if (isLoading) {
        return <Loading />
    }

    return (
        <Box sx={{ px: 2, py: 1 }}>
            {currentImageList.length > 0 ? (
                <ImageList images={currentImageList} showRemoveImage={false} />
            ) : (
                <Typography variant='h6'>{t('noAttachmentsFound')}</Typography>
            )}
        </Box>
    )
}

DispatchAttachments.propTypes = { ticketId: PropTypes.number }

export default DispatchAttachments
