import LabelStatus from '~/components/LabelStatus'
import { useTranslation } from 'react-i18next'
import { capitalize } from '~/utils/helpers'
import PropTypes from 'prop-types'
import { ACTIVE, INVITED, REJECTED } from './constants'

const LabelStatusUser = ({ state }) => {
    const { t } = useTranslation()

    return (
        <LabelStatus
            variant='ghost'
            color={
                (state === REJECTED && 'error') ||
                (state === ACTIVE && 'success') ||
                (state === INVITED && 'primary') ||
                'warning'
            }
        >
            {capitalize(t(`pages.user.userDetail.${state.toLowerCase()}`))}
        </LabelStatus>
    )
}

LabelStatusUser.propTypes = { state: PropTypes.string }

export default LabelStatusUser
