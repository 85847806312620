import styled from '@emotion/styled'
import SectionName from '~/components/SectionName'
import { Field, Form } from 'react-final-form'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import SubmitButton from '~/components/SubmitButton'

import { useImportSerialQueueMutation } from './mutate'

import {
    Box,
    Grid,
    Card,
    CardContent,
    Stack,
    Typography,
    Button,
} from '@mui/material'
import { FLEET_IMPORT_DEVICES } from '~/constants/Routes'
import ComponentTooltip from '~/components/ComponentTooltip'
import { UploadFile } from '@mui/icons-material'

const ErrorBox = styled(Box)({
    maxHeight: '7rem',
    overflow: 'auto',
})

const ErrorTypography = styled(Typography)({
    color: '#d32f2f',
    fontFamily: "'Lato',sans-serif",
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
})

const ImportSerialQueue = () => {
    const { t } = useTranslation()
    const [selectedFile, setSelectedFile] = useState()
    const [fileName, setFileName] = useState('')
    const [failures, setFailures] = useState([])
    const [error, setError] = useState([])
    const { openSnackbar } = useDialogContext()
    const { mutate: actionImport, isLoading } = useImportSerialQueueMutation()

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0])
        setError([])
        setFailures([])
    }

    const onSubmit = useCallback(() => {
        actionImport(selectedFile, {
            onSuccess: (data) => {
                if (data.data.status === 'success') {
                    openSnackbar({
                        message: t(`message.importSuccess`),
                        type: 'success',
                    })
                }
            },
            onError: (e) => {
                setFailures(e.response.data.data)
                setError(e.response.data.message)
                openSnackbar({
                    message: t(`message.importFailed`),
                    type: 'error',
                })
            },
        })
    }, [actionImport, selectedFile, openSnackbar, t])

    return (
        <>
            <Form
                onSubmit={onSubmit}
                render={() => (
                    <Grid item xs={12} md={6}>
                        <ComponentTooltip
                            componentId={`import_device_session_serial_queue`}
                            pagePath={FLEET_IMPORT_DEVICES}
                        >
                            <div>
                                <SectionName
                                    name={t(
                                        `pages.device.importDevices.importSerialQueue`,
                                    )}
                                />
                            </div>
                        </ComponentTooltip>
                        <Card style={{ height: 'fit-content' }}>
                            <CardContent>
                                <Stack direction='row' spacing={3}>
                                    <Grid
                                        container
                                        direction='column'
                                        alignItems='center'
                                        sx={{
                                            border: '1px solid gray',
                                            borderRadius: '2px',
                                            padding: 2,
                                            borderStyle: 'dotted',
                                        }}
                                    >
                                        <UploadFile />
                                        <Typography>
                                            ({t('message.pleaseSelectCSVType')})
                                        </Typography>
                                        <Typography sx={{ padding: '10px 0' }}>
                                            {fileName && `(${fileName})`}
                                        </Typography>

                                        <Field>
                                            {(props) => {
                                                const handleFileChange = (
                                                    event,
                                                ) => {
                                                    setFileName(
                                                        event.target.files[0]
                                                            ?.name || '',
                                                    )
                                                    changeHandler(event)
                                                }

                                                return (
                                                    <Box>
                                                        <input
                                                            id='file-import-serial-queue'
                                                            name='file'
                                                            type='file'
                                                            accept='.csv'
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                            onChange={
                                                                handleFileChange
                                                            }
                                                        />
                                                        <label htmlFor='file-import-serial-queue'>
                                                            <Button
                                                                variant='contained'
                                                                size='small'
                                                                component='span'
                                                                sx={{
                                                                    textTransform:
                                                                        'none',
                                                                    backgroundColor:
                                                                        '#76B72A',
                                                                    '&:hover': {
                                                                        backgroundColor:
                                                                            '#102F44',
                                                                        color: 'white',
                                                                    },
                                                                }}
                                                            >
                                                                {t(
                                                                    'button.browse',
                                                                )}
                                                            </Button>
                                                        </label>
                                                    </Box>
                                                )
                                            }}
                                        </Field>
                                    </Grid>
                                </Stack>
                                <Stack
                                    direction='row-reverse'
                                    sx={{ marginTop: 1 }}
                                >
                                    <SubmitButton
                                        isLoading={isLoading}
                                        handleSubmit={onSubmit}
                                        name={`${t('button.import')}`}
                                    />
                                </Stack>
                                {((failures && failures.length > 0) ||
                                    (error && error.length > 0)) && (
                                    <Stack direction='row' spacing={3}>
                                        <Grid item xs={12}>
                                            {failures &&
                                                failures.length > 0 && (
                                                    <ErrorBox>
                                                        {failures.map(
                                                            (index) => (
                                                                <ErrorTypography
                                                                    key={
                                                                        index.row
                                                                    }
                                                                >
                                                                    (Row:{' '}
                                                                    {index.row}){' '}
                                                                    {index.contents.map(
                                                                        (
                                                                            content,
                                                                        ) =>
                                                                            content,
                                                                    )}
                                                                </ErrorTypography>
                                                            ),
                                                        )}
                                                    </ErrorBox>
                                                )}
                                            {error && (
                                                <ErrorBox>
                                                    <ErrorTypography>
                                                        {error}
                                                    </ErrorTypography>
                                                </ErrorBox>
                                            )}
                                        </Grid>
                                    </Stack>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            />
        </>
    )
}

export default ImportSerialQueue
