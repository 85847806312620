import { IconButton, Radio, TableBody, TableRow } from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import TableLoading from '~/components/Table/TableLoading'
import { TableCell } from '~/components/TableCell'
import { headCells } from './constants'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUserDetailInformation } from '~/store/user/selector'
import { ActionTeamConfirm } from '~/pages/UserDetail/components/UserTeams/ActionTeamConfirm'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { ACTIVE } from '~/pages/User/constants'

const UserTeamBody = ({ isLoading, teams, currentTeamId }) => {
    const { t } = useTranslation()

    const [openActionConfirm, setOpenActionConfirm] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState({})
    const [forDeleting, setForDeleting] = useState(false)
    const selectedUser = useSelector(selectUserDetailInformation)
    const isShownDeletionButton = useMemo(
        () => !(selectedUser.state === ACTIVE && teams.length === 1),
        [teams, selectedUser],
    )

    const openActiveConfirmPopup = (team) => {
        setForDeleting(false)
        setSelectedTeam(team)
        setOpenActionConfirm(true)
    }

    const openDeletingConfirmPopup = (team) => {
        setForDeleting(true)
        setSelectedTeam(team)
        setOpenActionConfirm(true)
    }

    if (isLoading) {
        return <TableLoading colSpan={headCells.length} />
    }

    if (teams.length < 1) {
        return (
            <TableBody>
                <DataNotFound
                    colSpan={headCells.length}
                    message={t('message.noTeam')}
                />
            </TableBody>
        )
    }

    return (
        <>
            <TableBody>
                {teams?.map((row, index) => (
                    <TableRow
                        key={index}
                        sx={{
                            '&:last-child td, &:last-child th': {
                                border: 0,
                            },
                        }}
                    >
                        <TableCell cellValue={row.id || '---'} />
                        <TableCell cellValue={row.name || '---'} />
                        <TableCell cellValue={row.description || '---'} />
                        <TableCell
                            cellValue={row?.service_center?.name || '---'}
                        />
                        <TableCell
                            cellValue={
                                row.created_at ? (
                                    <DateTimeLocale datetime={row.created_at} />
                                ) : (
                                    '---'
                                )
                            }
                        />
                        <TableCell
                            cellValue={
                                <Radio
                                    checked={row?.id === currentTeamId}
                                    onChange={() => openActiveConfirmPopup(row)}
                                    value={row?.id}
                                    name='active-group'
                                />
                            }
                        />
                        {isShownDeletionButton && (
                            <TableCell
                                cellValue={
                                    <IconButton size='small'>
                                        <DeleteOutlineIcon
                                            onClick={() => {
                                                openDeletingConfirmPopup(row)
                                            }}
                                            color='error'
                                        />
                                    </IconButton>
                                }
                            />
                        )}
                    </TableRow>
                ))}
            </TableBody>
            {openActionConfirm && selectedUser?.id && (
                <ActionTeamConfirm
                    team={selectedTeam}
                    userId={selectedUser?.id}
                    open={openActionConfirm}
                    onClose={() => setOpenActionConfirm(false)}
                    forDeleting={forDeleting}
                />
            )}
        </>
    )
}

UserTeamBody.propTypes = {
    isLoading: PropTypes.bool,
    teams: PropTypes.arrayOf(PropTypes.object),
    currentTeamId: PropTypes.number,
}

export default UserTeamBody
