import { CircularProgress, Grid } from '@mui/material'
import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import DeleteConfirmPopup from '~/components/DeleteConfirmPopup'
import PageName from '~/components/PageName'
import { ADMINISTRATOR_USER_DETAIL } from '~/constants/Routes'
import UserAttributes from './components/UserAttributes'
import UserInformation from './components/UserInformation'
import useUser from './Hooks/useUser'
import UserDetailToolbar from './UserDetailToolbar'

const UserProfile = () => {
    const {
        id,
        localization,
        isDelete,
        snackbar,
        deleteInvitedUser,
        isLoading,
        BreadComponents,
        changeDisableUser,
        changeUserStatus,
        deleteUser,
        handleClose,
        impersonateUser,
        user,
        t,
    } = useUser()
    if (isLoading) {
        return <CircularProgress size='25px' />
    }

    if (!user) {
        return null
    }

    return (
        <>
            {snackbar}
            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={ADMINISTRATOR_USER_DETAIL}
            >
                <div>
                    <PageName name='User Information' />
                </div>
            </ComponentTooltip>
            <UserDetailToolbar
                changeUserStatus={changeUserStatus}
                user={user}
                localization={localization}
                deleteUser={deleteUser}
                changeDisableUser={changeDisableUser}
                impersonateUser={impersonateUser}
            />
            <DeleteConfirmPopup
                openDeleteConfirm={isDelete}
                {...{ handleClose }}
                handleDelete={() => deleteInvitedUser({ id })}
                informationDialog={{
                    title: `${t('dialog.deleteUser')}`,
                    content: `${t('dialog.deleteUserContent')}`,
                }}
            />

            <Grid container spacing={3}>
                <UserInformation />
                <UserAttributes />
            </Grid>
        </>
    )
}
export default UserProfile
