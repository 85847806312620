import axiosClient, { axiosFormClient, clientDownload } from '~/api/base'

class UserApi {
    getUsers = (params) => {
        return axiosClient.get('api/users', {
            params,
        })
    }

    getUserStates = () => {
        return axiosClient.get('api/users/all-states')
    }

    getDisabledUsers = (params) => {
        return axiosClient.get('api/users/disabled', {
            params,
        })
    }

    getUserProfile = (id) => axiosClient.get(`api/users/${id}`)

    updateStatusUser = (id, payload) =>
        axiosClient.put(`api/users/${id}/update_status`, payload)

    updateDisableUser = (id, payload) =>
        axiosClient.put(`api/users/${id}`, payload)

    inviteUser = (payload) => axiosClient.post('api/users/invite', payload)

    getUsersExport = (payload) =>
        clientDownload.post('api/users/export-users', payload)

    getUsersImport = (payload) =>
        axiosFormClient.post('api/users/import-users', payload)

    updateRole = (id, payload) =>
        axiosClient.put(`api/users/${id}/update_role`, payload)

    updateUserTimezone = (id, payload) =>
        axiosClient.put(`api/users/${id}/update_timezone`, payload)

    deleteInvitedUser = (id) => axiosClient.delete(`api/users/${id}`)

    updateSettingUser = (id, { payload }) =>
        axiosClient.put(`api/users/${id}/setting`, payload)

    updateWorkbench = (payload) =>
        axiosFormClient.put('api/user/update-workbench', payload)

    updateTeam = (payload) =>
        axiosFormClient.put('api/user/update-team', payload)

    getUserTeams = (params) =>
        axiosClient.get(`api/users/${params?.id}/teams`, {
            params,
        })

    getTeamsUserNotIn = (userId) =>
        axiosClient.get(`api/definition/teams-user-not-in/${userId}`)

    joinTeam = (payload) => axiosClient.put(`api/definition/join-team`, payload)
    switchActiveTeam = (payload) =>
        axiosClient.put(`api/definition/switch-active-team`, payload)

    removeTeam = (payload) =>
        axiosClient.put(`api/definition/remove-team`, payload)

    impersonateUser = (userId) =>
        axiosClient.post(`api/users/${userId}/impersonate`)

    loginUserSwitchActiveTeam = (payload) =>
        axiosClient.put(`api/definition/login-user-switch-active-team`, payload)

    getTeamsByServiceCenter = (serviceCenterId) =>
        axiosClient.get(`api/users/teams-by-service-center/${serviceCenterId}`)
}

export default new UserApi()
