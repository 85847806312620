import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import { TableBody, TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import LoadingCircle from '~/components/LoadingCircle'
import { useTranslation } from 'react-i18next'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const LogInformation = ({ logInfors, isLoading }) => {
    const localization = 'pages.administrator.activities.'
    const { t } = useTranslation()
    if (isLoading) {
        return <LoadingCircle />
    }
    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {logInfors.map((data, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell
                                    sx={{
                                        width: '250px',
                                        borderBottom: 'none',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {data.key}:
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                    }}
                                >
                                    {data.key ===
                                    t(`${localization}createdAt`) ? (
                                        <DateTimeLocale datetime={data.value} />
                                    ) : (
                                        <> {data.value}</>
                                    )}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

LogInformation.propTypes = {
    isLoading: PropTypes.bool,
    logInfors: PropTypes.array,
}

export default LogInformation
