import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowedPartCode } from '~/store/partcode/selector'
import { pushShowPartCodes } from '~/store/partcode/slice'
import Button from '../Button'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const PartCodeListSelectorComponent = ({ partCode }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'button' })
    const dispatch = useDispatch()
    const showingPartCode = useSelector(selectShowedPartCode)

    const isDisabled =
        partCode.added ||
        showingPartCode.some(
            (showingPart) => showingPart?.code === partCode.code,
        )

    const addPart = () => {
        dispatch(pushShowPartCodes([...showingPartCode, partCode]))
    }

    return (
        <TableRow>
            <TableCell>{partCode.code}</TableCell>
            <TableCell>{partCode.name}</TableCell>
            <TableCell align='center'>
                <Button disabled={isDisabled} onClick={addPart}>
                    {t('add')}
                </Button>
            </TableCell>
        </TableRow>
    )
}

PartCodeListSelectorComponent.propTypes = { partCode: PropTypes.object }

export default PartCodeListSelectorComponent
