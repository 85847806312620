import { Card, CardContent } from '@mui/material'
import PropTypes from 'prop-types'

const Layout = ({ children }) => {
    return (
        <Card variant='outlined'>
            <CardContent>{children}</CardContent>
        </Card>
    )
}

Layout.propTypes = { children: PropTypes.any }

export default Layout
