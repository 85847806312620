import PropTypes from 'prop-types'
import { useMemo } from 'react'
import TableFilters from '~/components/TableFilters'
import { useRolesQuery } from '../Acl/Role/query'
import { useUserStatesQuery } from './query'

const UserTableFilters = ({
    search,
    searchHandler,
    role,
    setRole,
    state,
    setState,
    onEnterSearch,
}) => {
    const { data: roleData, isSuccess: isSuccessRole } = useRolesQuery({
        menuSelect: true,
    })

    const { data: stateData } = useUserStatesQuery()

    const states = useMemo(() => {
        const result =
            stateData?.states?.map((item) => ({
                id: item,
                name: item,
            })) ?? []
        result.unshift({ id: '', name: 'ALL' })
        return result
    }, [stateData])

    const roles = useMemo(() => {
        if (isSuccessRole) {
            const result = [...roleData?.data]
            result.unshift({ id: '', name: 'All' })
            return result
        }
        return []
    }, [roleData?.data, isSuccessRole])

    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Role',
                handleFunc: setRole,
                dataSet: roles,
                value: role,
            },
            {
                label: 'States',
                handleFunc: setState,
                dataSet: states,
                value: state,
            },
        ]
    }, [role, roles, setRole, setState, state, states])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='users'
            page='users'
        />
    )
}
UserTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    role: PropTypes.string,
    setRole: PropTypes.func,
    state: PropTypes.string,
    setState: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default UserTableFilters
