import { OpenInNew } from '@mui/icons-material'
import {
    Box,
    Grid,
    IconButton,
    Link,
    Stack,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BarcodeGenerator from '~/components/BarcodeGenerator/BarcodeGenerator'
import { FLEET_SERIAL_DETAIL_SEARCH } from '~/constants/Routes'
import WarrantyTableBody from '~/pages/Issue/QualityCheck/Component/WarrantyTableBody'
import {
    serialPrintLink,
    serviceIdPrintLink,
    ticketExternalLink,
} from '~/utils/helpers'
import GreenSphere from '../../assets/images/green_sphere.png'
import RedSphere from '../../assets/images/red_sphere.png'
import SectionTitle from './components/SectionTitle'
import { LOCALIZATION } from './constants'
import useStyles from './hooks/useStyles'
import { useParams } from 'react-router-dom'
import { useIssueQuery } from '~/pages/Issue/query'
import Loading from '~/components/Loading'
import EntitlementsAccordions from './EntitlementsAccordions'

const TicketDetails = ({ data, titleStype }) => {
    const { t } = useTranslation()
    const [isExpand, setIsExpand] = useState(false)
    const { issueId } = useParams()
    const { data: issue, isLoading } = useIssueQuery(issueId)

    const handleChangeExpandAccordion = () => {
        setIsExpand((prev) => !prev)
    }

    const technicianComment = useMemo(() => {
        if (!isLoading && issue?.data?.previous_id != null) {
            return issue?.data?.description.substring(
                issue?.data?.description.lastIndexOf(' | '),
            )
        }
        return ''
    }, [issue, isLoading])

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <>
            <SectionTitle
                title={t(LOCALIZATION + `ticket_details`)}
                xs={12}
                md={12}
                lg={12}
                align='center'
                sx={titleStype}
            />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <DeviceTableWithBarcode
                        deviceData={data}
                        deviceSessionData={
                            issue?.data?.latest_device_register_session
                        }
                        url={issue?.url}
                        technicianComment={technicianComment}
                    />
                </Grid>
                <Grid item xs={12} className='wont-print'>
                    <EntitlementsAccordions
                        isExpanded={isExpand}
                        handleChange={handleChangeExpandAccordion}
                        title={'entitlements'}
                        content={
                            <WarrantyTable warrantyData={data?.warranties} />
                        }
                    />
                </Grid>
            </Grid>
        </>
    )
}

const DeviceTableWithBarcode = ({
    deviceData,
    deviceSessionData,
    technicianComment,
    url,
}) => {
    const { t } = useTranslation()
    return (
        <>
            <TableContainer
                component='div'
                sx={{
                    border: '0.5px solid lightgray',
                    borderRadius: '6px',
                    marginTop: '0px',
                }}
            >
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell
                                sx={{
                                    width: '130px',
                                }}
                            >
                                {t(LOCALIZATION + `serial`)}
                            </TableCell>
                            <TableCell>
                                <Link
                                    href={FLEET_SERIAL_DETAIL_SEARCH.replace(
                                        ':serial',
                                        deviceData?.serial,
                                    )}
                                    sx={{
                                        textDecoration: 'none',
                                    }}
                                    target='_blank'
                                >
                                    {deviceData?.serial}
                                </Link>
                                <IconButton
                                    href={serialPrintLink(
                                        deviceData?.service_id,
                                        url,
                                    )}
                                    target='_blank'
                                    sx={{ padding: '0 8px' }}
                                >
                                    <Box
                                        component='div'
                                        className='material-symbols-rounded'
                                    >
                                        barcode_scanner
                                    </Box>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t(LOCALIZATION + `ticketNumber`)}
                            </TableCell>
                            <TableCell>
                                <Stack direction='row' alignItems='center'>
                                    <Link
                                        target='_blank'
                                        underline='none'
                                        href={ticketExternalLink(
                                            deviceData?.ticker_number,
                                            url,
                                        )}
                                        id='ticket-id'
                                    >
                                        {deviceData?.ticker_number}
                                        <span id='change-to-bar-code'>
                                            <IconButton
                                                sx={{
                                                    padding: 0,
                                                    marginLeft: '3px',
                                                    marginBottom: '3px',
                                                }}
                                            >
                                                <OpenInNew />
                                            </IconButton>
                                        </span>
                                    </Link>
                                    <BarcodeGenerator
                                        barcodeKey='ticket-id'
                                        barcodeString={deviceData?.ticker_number.toString()}
                                        options={{
                                            height: 30,
                                            displayValue: false,
                                        }}
                                        style={{ display: 'none' }}
                                        id='barcode-ticket-id-container'
                                    />
                                </Stack>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t(LOCALIZATION + `esdLocation`)}
                            </TableCell>
                            <TableCell>{deviceData?.esd_location}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t(LOCALIZATION + `device_model`)}
                            </TableCell>
                            <TableCell>{deviceData?.device_model}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t(LOCALIZATION + `service_id`)}
                            </TableCell>
                            <TableCell>
                                {deviceData?.service_id}
                                <IconButton
                                    href={serviceIdPrintLink(
                                        deviceData?.service_id,
                                        url,
                                    )}
                                    target='_blank'
                                    sx={{ padding: '0 8px' }}
                                >
                                    <Box
                                        component='div'
                                        className='material-symbols-rounded'
                                    >
                                        barcode_scanner
                                    </Box>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t(LOCALIZATION + `device_sku`)}
                            </TableCell>
                            <TableCell>{deviceData?.product_sku}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t(LOCALIZATION + `image_version`)}
                            </TableCell>
                            <TableCell>
                                {deviceData?.image_version_tid}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    width: '130px',
                                }}
                            >
                                {t(LOCALIZATION + `customer_complaint`)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    width: '150px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        width: '200px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    <Tooltip
                                        placement='top-start'
                                        arrow
                                        title={
                                            deviceData?.ticket_description +
                                            technicianComment
                                        }
                                    >
                                        <span>
                                            {deviceData?.ticket_description +
                                                technicianComment}
                                        </span>
                                    </Tooltip>
                                </Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell
                                sx={{
                                    width: '130px',
                                }}
                            >
                                {t(LOCALIZATION + `enroll_state`)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    width: '150px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        width: '200px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    <Tooltip
                                        placement='top-start'
                                        arrow
                                        title={
                                            deviceSessionData?.device_state_message
                                        }
                                    >
                                        <span>
                                            {deviceSessionData?.device_state}
                                        </span>
                                    </Tooltip>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t(LOCALIZATION + `motherboard_serial`)}
                            </TableCell>
                            <TableCell>
                                {deviceData?.motherboard_serial?.serial}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

const WarrantyTable = ({ warrantyData }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const getSphereIcon = useCallback((warrantyEnd) => {
        const warrantyEndDate = new Date(warrantyEnd)
        warrantyEndDate.setDate(new Date(warrantyEnd).getDate() + 1)

        const today = new Date()

        return (
            <img
                src={warrantyEndDate < today ? RedSphere : GreenSphere}
                alt='warranty_sphere'
                width={15}
                height={15}
            />
        )
    }, [])

    return (
        <TableContainer
            sx={{
                borderTop: '0px solid lightgray',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: '#f2f2f2',
                        }}
                    >
                        <TableCell
                            sx={{
                                border: '0.5px solid lightgray',
                            }}
                        >
                            {t(LOCALIZATION + `warranty_code`)}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: '0.5px solid lightgray',
                            }}
                        >
                            {t(LOCALIZATION + `warranty_start`)}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: '0.5px solid lightgray',
                            }}
                        >
                            {t(LOCALIZATION + `warranty_end`)}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: '0.5px solid lightgray',
                            }}
                        />
                    </TableRow>
                </TableHead>
                <WarrantyTableBody
                    {...{ warrantyData, classes, getSphereIcon }}
                />
            </Table>
        </TableContainer>
    )
}

TicketDetails.propTypes = {
    data: PropTypes.object,
    titleStype: PropTypes.object,
}

DeviceTableWithBarcode.propTypes = {
    deviceData: PropTypes.object,
    deviceSessionData: PropTypes.object,
    technicianComment: PropTypes.string,
    url: PropTypes.string,
}

WarrantyTable.propTypes = {
    warrantyData: PropTypes.array,
}

export default TicketDetails
