import {
    Box,
    Typography,
    Grid,
    Paper,
    Card,
    CardContent,
    Stack,
    Button,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { UploadFile } from '@mui/icons-material'
import ImportRemittanceTable from './ImportRemittanceTable'
import {
    useDispatchRemittanceMutation,
    useDispatchRemittanceQuery,
} from './query'
import { useSnackbar } from '~/hooks/useSnackbar'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'
import BreadCrumb from '~/components/BreadCrumb'
import ComponentTooltip from '~/components/ComponentTooltip'
import {
    CLAIM_DISPUTE,
    CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE,
} from '~/constants/Routes'
import PageName from '~/components/PageName'
import SubmitButton from '~/components/SubmitButton'

const ImportRemittancePage = () => {
    const localization = 'pages.dispute.remittance'
    const BreadComponents = [
        { route: CLAIM_DISPUTE, title: 'Disputes' },
        {
            route: CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE,
            title: 'ImportDevices',
        },
    ]
    const { t } = useTranslation()
    const [fileName, setFileName] = useState('')
    const [selectedFile, setSelectedFile] = useState(false)
    const {
        mutate,
        isLoading,
        isSuccess,
        isError,
        data: mutationResult,
        error: mutationError,
    } = useDispatchRemittanceMutation()
    const { data: queryData } = useDispatchRemittanceQuery()
    const { showSnackbar, snackbar } = useSnackbar()
    const onSubmit = useCallback(
        (values) => {
            mutate(selectedFile)
        },
        [mutate, selectedFile],
    )

    useEffect(() => {
        if (isSuccess) {
            if (mutationResult?.code === 'success') {
                showSnackbar(mutationResult?.message, 'success')
            } else {
                showSnackbar(mutationResult?.message, 'error')
            }
        }

        if (isError) {
            showSnackbar(mutationError?.message, 'error')
        }
    }, [
        isSuccess,
        isError,
        mutationResult?.code,
        mutationResult?.message,
        showSnackbar,
        mutationError?.message,
    ])
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0])
    }

    return (
        <>
            {snackbar}

            <BreadCrumb breadcrumbs={BreadComponents} />
            <ComponentTooltip
                componentId={`page_title`}
                pagePath={CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE}
            >
                <div>
                    <PageName name={t(`${localization}.pageName`)} />
                </div>
            </ComponentTooltip>

            <Grid container md={6}>
                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit, form }) => (
                        <Grid item xs={12} md={12}>
                            <Card>
                                <CardContent>
                                    <Grid container>
                                        <Typography variant='h6' gutterBottom>
                                            {t(`${localization}.fileUpload`)}
                                        </Typography>
                                    </Grid>

                                    <Stack direction={'row'} spacing={3}>
                                        <Grid container>
                                            <Grid
                                                item
                                                container
                                                direction='column'
                                                alignItems='center'
                                                sx={{
                                                    border: '1px solid gray',
                                                    borderRadius: '2px',
                                                    padding: 2,
                                                    borderStyle: 'dotted',
                                                }}
                                            >
                                                <UploadFile />
                                                <Typography>
                                                    (
                                                    {t(
                                                        'message.pleaseSelectXMLType',
                                                    )}
                                                    )
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        padding: '10px 0',
                                                    }}
                                                >
                                                    {fileName &&
                                                        `(${fileName})`}
                                                </Typography>
                                                <Field>
                                                    {(props) => {
                                                        const handleFileChange =
                                                            (event) => {
                                                                setFileName(
                                                                    event.target
                                                                        .files[0]
                                                                        ?.name ||
                                                                        '',
                                                                )
                                                                changeHandler(
                                                                    event,
                                                                )
                                                            }

                                                        return (
                                                            <Box>
                                                                <input
                                                                    id='file'
                                                                    name='file'
                                                                    type='file'
                                                                    style={{
                                                                        display:
                                                                            'none',
                                                                    }}
                                                                    accept='.xml'
                                                                    onChange={
                                                                        handleFileChange
                                                                    }
                                                                />
                                                                <label htmlFor='file'>
                                                                    <Button
                                                                        variant='contained'
                                                                        size='small'
                                                                        component='span'
                                                                        sx={{
                                                                            textTransform:
                                                                                'none',
                                                                            backgroundColor:
                                                                                '#76B72A',
                                                                            '&:hover':
                                                                                {
                                                                                    backgroundColor:
                                                                                        '#102F44',
                                                                                    color: 'white',
                                                                                },
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            'button.browse',
                                                                        )}{' '}
                                                                    </Button>
                                                                </label>
                                                            </Box>
                                                        )
                                                    }}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack
                                        direction='row-reverse'
                                        sx={{ marginTop: 1 }}
                                    >
                                        <SubmitButton
                                            isLoading={isLoading}
                                            handleSubmit={onSubmit}
                                            disabled={!selectedFile}
                                            name={`${t('button.import')}`}
                                        />
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                />
            </Grid>

            <Paper
                sx={{
                    py: 1,
                    px: 2,
                    height: '100%',
                    mb: 2,
                    mt: 2,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item container>
                        <Typography variant='h6' gutterBottom>
                            {t(`${localization}.importHistory`)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            sx={{
                                color: '#102F44',
                                fontFamily: "'Lato',sans-serif",
                                fontSize: '1 rem',
                                fontWeightL: 400,
                                lineHeight: 1.66,
                            }}
                        >
                            {t(`${localization}.oldestImport`)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <DateTimeLocale datetime={queryData?.oldestImport} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography
                            sx={{
                                color: '#102F44',
                                fontFamily: "'Lato',sans-serif",
                                fontSize: '1 rem',
                                fontWeightL: 400,
                                lineHeight: 1.66,
                            }}
                        >
                            {t(`${localization}.latestImport`)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <DateTimeLocale datetime={queryData?.latestImport} />
                    </Grid>
                </Grid>
            </Paper>

            <Paper
                sx={{
                    py: 1,
                    px: 2,
                    height: '100%',
                }}
            >
                <Grid container>
                    <Grid item container>
                        <Typography variant='h6' gutterBottom>
                            {t(`${localization}.importedFile`)}
                        </Typography>
                    </Grid>

                    <ImportRemittanceTable data={queryData?.fileNames} />
                </Grid>
            </Paper>
        </>
    )
}

export default ImportRemittancePage
