import {
    Box,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Toolbar,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import SendIcon from '@mui/icons-material/Send'
import ToolBarButton from '~/components/ToolBarButton'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { isProductionApp } from '~/utils/helpers'
import { useSelector } from 'react-redux'
import { selectUser } from '~/store/auth/selector'
import usePermissions from '~/hooks/usePermission'
import { ACTIVE, DISABLED, INVITED, PENDING } from '../User/constants'

const UserDetailToolbar = ({
    changeUserStatus,
    user,
    localization,
    deleteUser,
    changeDisableUser,
    impersonateUser,
}) => {
    const { t } = useTranslation()
    const unapprovable = useMemo(() => user.team_name.length === 0, [user])
    const loggedUser = useSelector(selectUser)
    const { permissions } = usePermissions()

    const impersonateSection = useMemo(() => {
        const canImpersonateUser =
            !isProductionApp &&
            loggedUser?.id !== user.id &&
            permissions.administrator.user.canImpersonateUser

        if (!canImpersonateUser) return null

        return (
            <ToolBarButton
                icon={<SupervisedUserCircleIcon />}
                handleAction={() => impersonateUser(user.id)}
                name={t(`${localization}.impersonate`)}
            />
        )
    }, [loggedUser, permissions, user, localization, impersonateUser, t])

    return (
        <Toolbar
            disableGutters={false}
            sx={{
                bgcolor: '#C8FACD',
                borderRadius: '10px',
                my: 1,
                mr: 1,
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                {user?.state === DISABLED && (
                    <ListItem>
                        <ListItemIcon>
                            <Box
                                component='span'
                                className='material-symbols-rounded'
                                sx={{ color: '#102F44' }}
                            >
                                info
                            </Box>
                        </ListItemIcon>
                        <ListItemText
                            primary={`${t(`message.disableAccount`)}`}
                        />
                    </ListItem>
                )}
                {unapprovable && (
                    <ListItem>
                        <ListItemIcon>
                            <Box
                                component='span'
                                className='material-symbols-rounded'
                                sx={{ color: '#102F44' }}
                            >
                                info
                            </Box>
                        </ListItemIcon>
                        <ListItemText primary={`${t(`message.userNotTeam`)}`} />
                    </ListItem>
                )}
            </Box>

            <Stack direction='row-reverse' minWidth='46px' spacing={2}>
                {user?.state === PENDING && (
                    <>
                        <ToolBarButton
                            icon={<RemoveCircleOutlineIcon />}
                            color='error'
                            handleAction={() =>
                                changeUserStatus({
                                    id: user.id,
                                    action: 'rejected',
                                })
                            }
                            name={t(`${localization}.reject`)}
                        />
                        <ToolBarButton
                            icon={<CheckCircleOutlineIcon />}
                            handleAction={() =>
                                changeUserStatus({
                                    id: user.id,
                                    action: 'approved',
                                })
                            }
                            name={t(`${localization}.approve`)}
                            disabled={unapprovable}
                        />
                    </>
                )}
                {user?.state === INVITED && (
                    <>
                        <ToolBarButton
                            icon={<DeleteIcon />}
                            color='error'
                            handleAction={deleteUser}
                            name={t(`button.delete`)}
                        />
                        <ToolBarButton
                            icon={<SendIcon />}
                            handleAction={() =>
                                changeUserStatus({
                                    id: user.id,
                                    action: 'resend',
                                })
                            }
                            name={t(`${localization}.resend`)}
                        />
                    </>
                )}

                {user?.state === ACTIVE && (
                    <>
                        <ToolBarButton
                            icon={<RemoveCircleOutlineIcon />}
                            color='error'
                            handleAction={() =>
                                changeDisableUser({
                                    id: user.id,
                                    disabled: 1,
                                })
                            }
                            name={t(`button.disable`)}
                        />
                        {impersonateSection}
                    </>
                )}

                {user?.state === DISABLED && (
                    <ToolBarButton
                        icon={<CheckCircleOutlineIcon />}
                        handleAction={() =>
                            changeDisableUser({
                                id: user.id,
                                disabled: 0,
                            })
                        }
                        name={t(`button.enable`)}
                    />
                )}
            </Stack>
        </Toolbar>
    )
}

UserDetailToolbar.propTypes = {
    changeUserStatus: PropTypes.func,
    user: PropTypes.object,
    localization: PropTypes.string,
    deleteUser: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    changeDisableUser: PropTypes.func,
    impersonateUser: PropTypes.func,
}

export default UserDetailToolbar
