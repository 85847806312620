import propTypes from 'prop-types'
import { useMemo } from 'react'
import TableFilters from '~/components/TableFilters'
import { useActivityLogByUser } from './query'

const ActivityLogTableFilter = ({
    search,
    searchHandler,
    onEnterSearch,
    user,
    setUser,
    action,
    setAction,
    rangeDate,
    setRangeDate,
    type,
    setType,
    showRangePicker = true,
}) => {
    const states = useMemo(
        () => [
            { name: 'All', id: '' },
            { name: 'Created', id: 'Created' },
            { name: 'Updated', id: 'Updated' },
            { name: 'Deleted', id: 'Deleted' },
            { name: 'Impersonated', id: 'Impersonated' },
        ],
        [],
    )

    const userTypes = useMemo(
        () => [
            { name: 'All', id: '' },
            { name: 'User', id: 'USER' },
            { name: 'Service Account', id: 'SERVICE_ACCOUNT' },
        ],
        [],
    )

    const { data, isLoading: isUserLoading } = useActivityLogByUser({
        userType: type ?? null,
    })
    const userList = useMemo(() => {
        if (!data || isUserLoading) {
            return [{ name: 'All', id: '' }]
        }

        let result = []
        switch (type) {
            case 'USER':
                result = data?.users
                break
            case 'SERVICE_ACCOUNT':
                result = data?.service_accounts
                break
            default:
                result = data?.all
        }

        const dt = result?.map((item) => {
            return {
                id: item.id,
                name: item.name ?? item.email,
            }
        })
        dt.unshift({ id: '', name: 'All' })

        return dt
    }, [type, data, isUserLoading])

    const selectGroup = useMemo(
        () => [
            {
                label: 'Action',
                handleFunc: setAction,
                dataSet: states,
                value: action,
            },
            {
                label: 'User Type',
                handleFunc: setType,
                dataSet: userTypes,
                value: type,
            },
            {
                label: 'User',
                handleFunc: setUser,
                dataSet: userList,
                value: user,
            },
        ],
        [
            action,
            setAction,
            setType,
            setUser,
            states,
            type,
            user,
            userList,
            userTypes,
        ],
    )

    const rangeDateGroup = useMemo(() => {
        return showRangePicker
            ? [
                  {
                      label: 'Range Date',
                      handleFunc: setRangeDate,
                      value: rangeDate,
                  },
              ]
            : []
    }, [rangeDate, setRangeDate, showRangePicker])

    return (
        <TableFilters
            {...{
                selectGroup,
                search,
                searchHandler,
                onEnterSearch,
                rangeDateGroup,
            }}
            showDatePicker={true}
            searchPlaceHolder='des'
            page='activity-logs'
        />
    )
}

ActivityLogTableFilter.propTypes = {
    search: propTypes.string,
    searchHandler: propTypes.func,
    serviceCenter: propTypes.string,
    setServiceCenter: propTypes.func,
    onEnterSearch: propTypes.func,
    user: propTypes.string,
    setUser: propTypes.func,
    action: propTypes.string,
    setAction: propTypes.func,
    rangeDate: propTypes.any,
    setRangeDate: propTypes.func,
    type: propTypes.string,
    setType: propTypes.func,
    showRangePicker: propTypes.bool,
}

export default ActivityLogTableFilter
