import {
    Table as MuiTable,
    TableContainer,
    TableHead,
    TableRow,
    TableBody as MuiTableBody,
    TableCell,
} from '@mui/material'
import { useStyles } from 'tss-react'
import { useTranslation } from 'react-i18next'
import StyledTableCell from '~/pages/Diagnostic/components/StyledTableCell'
import DataNotFound from '~/components/DataNotFound'
import StyledTableRow from '~/pages/Diagnostic/components/StyledTableRow'
import PropTypes from 'prop-types'
import { isValidDateTimeString } from '~/utils/helpers'
import DateTimeLocale from '~/components/DateTimeLocale/DateTimeLocale'

const TableBody = ({ data, colSpan, isContainNewData }) => {
    const { classes } = useStyles()
    const formatDatetimeData = (value) => {
        if (isValidDateTimeString(value)) {
            return <DateTimeLocale datetime={value} />
        }
        if (typeof value === 'object') {
            return JSON.stringify(value)
        }
        if (typeof value === 'boolean') {
            return value.toString()
        }
        if (!value) {
            return 'N/A'
        }

        return value
    }
    return (
        <MuiTableBody>
            {data.length > 0 ? (
                data.map((row, index) => {
                    return (
                        <StyledTableRow key={index}>
                            <TableCell
                                align='left'
                                className={classes.tableCell}
                                sx={{ fontWeight: 'bold' }}
                            >
                                {formatDatetimeData(row?.key)}
                            </TableCell>
                            {isContainNewData && (
                                <TableCell
                                    align='left'
                                    className={classes.tableCell}
                                    sx={{
                                        maxWidth: '200px',
                                        wordWrap: 'break-word',
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    {formatDatetimeData(row?.original)}
                                </TableCell>
                            )}

                            <TableCell
                                align='left'
                                className={classes.tableCell}
                                sx={{
                                    maxWidth: '200px',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {formatDatetimeData(row?.new)}
                            </TableCell>
                        </StyledTableRow>
                    )
                })
            ) : (
                <DataNotFound colSpan={colSpan} />
            )}
        </MuiTableBody>
    )
}

TableBody.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    colSpan: PropTypes.number,
    isContainNewData: PropTypes.bool,
}

const DataChangeTable = ({ data, isContainNewData }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const LogDataChangeHeadCells = isContainNewData
        ? [
              {
                  id: 'key',
                  label: 'key',
              },
              {
                  id: 'original',
                  label: 'original',
              },
              {
                  id: 'new',
                  label: 'new',
              },
          ]
        : [
              {
                  id: 'key',
                  label: 'key',
              },
              {
                  id: 'data',
                  label: 'data',
              },
          ]

    return (
        <>
            <TableContainer
                sx={{ minWidth: 750, maxHeight: 600 }}
                aria-labelledby='tableTitle'
                size='medium'
            >
                <MuiTable>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {LogDataChangeHeadCells.map((item) => (
                                <StyledTableCell align='left' key={item.label}>
                                    {t(
                                        `pages.administrator.activities.${item.label}`,
                                    )}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        data={data}
                        colSpan={LogDataChangeHeadCells.length}
                        isContainNewData={isContainNewData}
                    />
                </MuiTable>
            </TableContainer>
        </>
    )
}

DataChangeTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    isContainNewData: PropTypes.bool,
}
export default DataChangeTable
