import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { DASHBOARD } from '../constants/Routes'
import { useDialogContext } from './providers/StyledDialogContext'
import { useTranslation } from 'react-i18next'
import usePermissions from '~/hooks/usePermission'

const ProtectedRoute = ({ permissions, canAny, children, ...props }) => {
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation()
    const { hasPermissions } = usePermissions()

    if (!hasPermissions(permissions, canAny)) {
        openSnackbar({
            message: t('message.you_do_not_have_permission'),
            type: 'error',
        })
        return <Navigate to={DASHBOARD} />
    }
    return children
}
ProtectedRoute.propTypes = {
    permissions: PropTypes.array,
    canAny: PropTypes.bool,
    children: PropTypes.node,
}

export default ProtectedRoute
