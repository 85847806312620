import { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import {
    ADMINISTRATOR,
    ADMINISTRATOR_ACTIVITY_LOGS,
    ADMINISTRATOR_ACTIVITY_LOGS_DETAILS,
    ADMINISTRATOR_DATASCHEDULER,
    ADMINISTRATOR_DATASCHEDULER_DETAIL,
    ADMINISTRATOR_DATASCHEDULER_NEW,
    ADMINISTRATOR_DEFINITION,
    ADMINISTRATOR_DEFINITION_CAUSE,
    ADMINISTRATOR_DEFINITION_CAUSE_EDIT,
    ADMINISTRATOR_DEFINITION_CAUSE_NEW,
    ADMINISTRATOR_DEFINITION_REGIONS,
    ADMINISTRATOR_DEFINITION_REGIONS_DETAIL,
    ADMINISTRATOR_DEFINITION_REGIONS_EDIT,
    ADMINISTRATOR_DEFINITION_REGIONS_NEW,
    ADMINISTRATOR_DEFINITION_SERVICECENTER,
    ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL,
    ADMINISTRATOR_DEFINITION_SERVICECENTER_EDIT,
    ADMINISTRATOR_DEFINITION_SERVICECENTER_NEW,
    ADMINISTRATOR_DEFINITION_TEAM,
    ADMINISTRATOR_DEFINITION_TEAM_DETAIL,
    ADMINISTRATOR_DEFINITION_TEAM_EDIT,
    ADMINISTRATOR_DEFINITION_TEAM_NEW,
    ADMINISTRATOR_DEFINITION_WORKBENCH,
    ADMINISTRATOR_DEFINITION_WORKBENCH_DETAIL,
    ADMINISTRATOR_DEFINITION_WORKBENCH_NEW,
    ADMINISTRATOR_NAMING_TAG,
    ADMINISTRATOR_NAMING_TAG_CREATE,
    ADMINISTRATOR_NAMING_TAG_DETAIL,
    ADMINISTRATOR_NAMING_TAG_RULE_CREATE,
    ADMINISTRATOR_NAMING_TAG_RULE_DETAIL,
    ADMINISTRATOR_ROLE,
    ADMINISTRATOR_ROLE_CREATE,
    ADMINISTRATOR_ROLE_DETAIL,
    ADMINISTRATOR_SYSTEM,
    ADMINISTRATOR_SYSTEM_API,
    ADMINISTRATOR_SYSTEM_ERRORLOG,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_DETAIL,
    ADMINISTRATOR_TEST_DATA_DIAGNOSE,
    ADMINISTRATOR_TEST_DATA_DISPATCH_SHIPMENT_REPORT,
    ADMINISTRATOR_TOOLTIP,
    ADMINISTRATOR_TOOLTIP_CREATE,
    ADMINISTRATOR_TOOLTIP_DETAIL,
    ADMINISTRATOR_USER,
    ADMINISTRATOR_USER_DETAIL,
    ADMINISTRATOR_USER_DISABLED,
    ADMINISTRATOR_USER_DISABLED_DETAIL,
    CLAIM,
    CLAIM_DISPUTE,
    CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE,
    CLAIM_INVOICE,
    CLAIM_RENUMERATION,
    DASHBOARD,
    DIAGNOSTIC,
    DIAGNOSTIC_DETAIL,
    FLEET_IMPORT_DEVICES,
    FLEET_MODEL,
    FLEET_MODEL_DETAIL,
    FLEET_OVERVIEW,
    FLEET_OVERVIEW_CHROME,
    FLEET_OVERVIEW_VENDOR,
    FLEET_OVERVIEW_WINDOW,
    FLEET_SERIAL,
    FLEET_SERIAL_DETAIL,
    FLEET_SERIAL_DETAIL_SEARCH,
    FLEET_SWAP,
    HOME,
    ISSUE_ARCHIVE,
    ISSUE_ARCHIVE_IMAGE,
    ISSUE_BOM,
    ISSUE_BOM_DETAIL,
    ISSUE_DETAIL,
    ISSUE_DISPATCH,
    ISSUE_DISPATCH_ADD,
    ISSUE_DISPATCH_COMPONENT,
    ISSUE_DISPATCH_DETAIL,
    ISSUE_DISPATCH_EDIT,
    ISSUE_DISPATCH_IMPORT_EXPORT,
    ISSUE_ISSUE,
    ISSUE_ISSUE_DETAIL,
    ISSUE_ISSUE_QC,
    ISSUE_ISSUE_REPAIR,
    ISSUE_OVERVIEW,
    ISSUE_RACKSCAN_SCAN,
    ISSUE_RACKSCAN_SCAN_DETAIL,
    ISSUE_RACKSCAN_SCAN_NEW,
    ISSUE_RACKSCAN_TICKET,
    KNOWLEDGE,
    PART,
    PART_COMPONENT,
    PART_COMPONENT_DETAIL,
    PART_DELL_STOCK,
    PART_PARTCODE,
    PART_PRODUCT,
    PART_PRODUCT_DETAIL,
    PART_USAGE,
    PROCUREMENT,
    PROCUREMENT_DELIVERY,
    PROCUREMENT_DELIVERY_DETAIL,
    PROCUREMENT_INVOICE,
    PROCUREMENT_ORDERCONFIRMATION,
    PROCUREMENT_ORDERS,
    PROCUREMENT_PURCHASEORDER,
    PROCUREMENT_PURCHASEORDER_DETAIL,
    PROCUREMENT_PURCHASEORDER_LINE_DETAIL,
    PROCUREMENT_RMA,
    PRODUCTION,
    PRODUCTION_ADD_ONS,
    PRODUCTION_ADD_ONS_DETAIL,
    PRODUCTION_IMAGES_DETAIL,
    PRODUCTION_IMPORT_PROJECTS,
    PRODUCTION_ORDERS,
    PRODUCTION_ORDERS_DETAIL,
    PRODUCTION_ORGANIZATION,
    PRODUCTION_PROJECTS,
    PRODUCTION_PROJECTS_DETAIL,
    PRODUCTION_SERVERS,
    PRODUCTION_SERVERS_DETAIL,
    PRODUCTION_SYSTEMIMAGE,
    USER_SETTING,
} from '~/constants/Routes'
import Role from '~/pages/Acl/Role'
import RoleCreate from '~/pages/Acl/RoleCreate'
import RoleDetail from '~/pages/Acl/RoleDetail'
import BOMPage from '~/pages/BOM/Detail'
import BOMOverview from '~/pages/BOM/Overview'
import CauseCreate from '~/pages/Cause/CauseCreate'
import CauseEdit from '~/pages/Cause/CauseEdit'
import Cause from '~/pages/Cause/Overview'
import DispatchComponent from '~/pages/ComponentDispatches'
import Component from '~/pages/ComponentPage'
import ComponentDetail from '~/pages/ComponentPage/Detail'
import DataSchedulerCreate from '~/pages/DataScheduler/DataScheduleCreate'
import DataSchedulerEdit from '~/pages/DataScheduler/DataScheduleEdit'
import DataScheduler from '~/pages/DataScheduler/Overview'
import DeliveryDetail from '~/pages/Delivery/DeliveryDetail'
import Delivery from '~/pages/Delivery/Overview'
import FleetOverview from '~/pages/Device/Fleet/Overview/FleetOverview'
import VendorPage from '~/pages/Device/Fleet/Vendor/VendorPage'
import ImportDevicesPage from '~/pages/Device/ImportDevices'
import SerialOverview from '~/pages/Device/Serial/Overview/SerialOverview'
import SerialPage from '~/pages/Device/Serial/SerialDetail'
import Dispatch from '~/pages/Dispatch'
import DispatchDetailPage from '~/pages/Dispatch/DispatchDetail'
import EditDispatch from '~/pages/Dispatch/EditDispatch'
import Home from '~/pages/Home'
import ImportRemittancePage from '~/pages/ImportRemittance/ImportRemittancePage'
import Invoice from '~/pages/Invoices/Overview'
import IssueDetail from '~/pages/Issue/Detail'
import IssueOverview from '~/pages/Issue/Overview'
import IssueQualityCheck from '~/pages/Issue/QualityCheck'
import IssueRepairPage from '~/pages/Issue/Repair'
import FleetModelDetails from '~/pages/Model/Details'
import Model from '~/pages/Model/Overview'
import OrderConfirmation from '~/pages/OrderConfirmation/Overview'
import PartCode from '~/pages/PartCode'
import Product from '~/pages/Product'
import ProductDetail from '~/pages/Product/ProductDetail'
import PurchaseOrder from '~/pages/PurchaseOrder/Overview'
import PurchaseOrderDetail from '~/pages/PurchaseOrder/PurchaseOrderDetail'
import PurchaseOrderLine from '~/pages/PurchaseOrder/PurchaseOrderLine'
import Ticket from '~/pages/RackTicket/Overview'
import Region from '~/pages/Region/Overview'
import RegionCreate from '~/pages/Region/RegionCreate'
import RegionDetail from '~/pages/Region/RegionDetail'
import RegionEdit from '~/pages/Region/RegionEdit'
import Scan from '~/pages/Scan/Overview'
import ScanCreate from '~/pages/Scan/ScanCreate'
import ScanDetail from '~/pages/Scan/ScanDetail'
import ServiceAccount from '~/pages/ServiceAccount'
import ServiceAccountCreate from '~/pages/ServiceAccount/ServiceAccountCreate'
import ServiceAccountDetail from '~/pages/ServiceAccount/ServiceAccountDetail'
import ServiceCenter from '~/pages/ServiceCenter/Overview'
import ServiceCenterCreate from '~/pages/ServiceCenter/ServiceCenterCreate'
import ServiceCenterDetail from '~/pages/ServiceCenter/ServiceCenterDetail'
import ServiceCenterEdit from '~/pages/ServiceCenter/ServiceCenterEdit'
import UserSetting from '~/pages/Setting'
import Team from '~/pages/Team/Overview'
import TeamCreate from '~/pages/Team/TeamCreate'
import TeamDetail from '~/pages/Team/TeamDetail'
import TeamEdit from '~/pages/Team/TeamEdit'
import Tooltip from '~/pages/Tooltip'
import TooltipCreate from '~/pages/Tooltip/Create'
import TooltipDetail from '~/pages/Tooltip/Detail'
import User from '~/pages/User'
import DisabledUser from '~/pages/User/DisabledUser'
import UserDetail from '~/pages/UserDetail'
import WorkBench from '~/pages/Workbench'
import WorkbenchCreate from '~/pages/Workbench/create'
import WorkbenchEdit from '~/pages/Workbench/detail'
import DellStock from '../pages/DellStock'
import Diagnostic from '../pages/Diagnostic'
import DiagnosticDetail from '../pages/Diagnostic/Detail'
import AddDispatch from '../pages/Dispatch/AddDispatch'
import NamingTag from '../pages/NamingTag'
import NamingTagRuleCreate from '../pages/NamingTag/Rule/Create'
import NamingTagRuleDetail from '../pages/NamingTag/Rule/Detail'
import NamingTagCreate from '../pages/NamingTag/Tag/Create'
import NamingTagDetail from '../pages/NamingTag/Tag/Detail'
import TicketDetail from '../pages/Ticket/Detail'
import TicketOverview from '../pages/Ticket/Overview'
import Layout from './layout'
import Placeholder from '~/pages/Placeholder'
import TestDataCreateDiagnose from '~/pages/TestData/Diagnose'
import TestDataIssues from '~/pages/TestData/DispatchShipmentReport'
import OrdersOverview from '~/pages/Production/Orders/Overview'
import OrderDetail from '~/pages/Production/Orders/Detail/OrderDetail'
import AddonsOverview from '~/pages/Production/Addons/Overview'
import AddonDetail from '~/pages/Production/Addons/Detail'
import ServersOverview from '~/pages/Production/Servers/Overview'
import ServersDetail from '~/pages/Production/Servers/Detail/ServersDetail'
import ImagesOverview from '~/pages/Production/Images/Overview'
import ImageDetail from '~/pages/Production/Images/Detail/ImageDetail'
import ProjectOverview from '~/pages/Production/Projects/Overview'
import ProjectDetail from '~/pages/Production/Projects/Detail/infor'
import ImportProject from '~/pages/Production/Projects/ProjectAction/ImportProject'
import DispatchesImportExport from '~/pages/ImportExportDispatch'
import ProcurementOrder from '~/pages/ProcurementOrder/Overview'
import ActivityLogs from '~/pages/ActivityLogs'
import ActivityLogsDetails from '~/pages/ActivityLogs/Details'
import ProtectedRoute from './ProtectedRoute'
import {
    PERMISSION_ADMINISTRATOR,
    PERMISSION_ADMINISTRATOR_ACTIVITY_LOG,
    PERMISSION_ADMINISTRATOR_DATASCHEDULER,
    PERMISSION_ADMINISTRATOR_DATASCHEDULER_CREATE,
    PERMISSION_ADMINISTRATOR_DATASCHEDULER_EDIT,
    PERMISSION_ADMINISTRATOR_DEFINITION,
    PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE,
    PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE_CREATE,
    PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER,
    PERMISSION_ADMINISTRATOR_DEFINITION_WORKBENCH,
    PERMISSION_ADMINISTRATOR_NAMING_TAG,
    PERMISSION_ADMINISTRATOR_PRODUCTION_ADD_ONS,
    PERMISSION_ADMINISTRATOR_PRODUCTION_SERVERS,
    PERMISSION_ADMINISTRATOR_REGION,
    PERMISSION_ADMINISTRATOR_ROLE,
    PERMISSION_ADMINISTRATOR_ROLE_CREATE,
    PERMISSION_ADMINISTRATOR_SYSTEM_API,
    PERMISSION_ADMINISTRATOR_SYSTEM_ERRORLOG,
    PERMISSION_ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
    PERMISSION_ADMINISTRATOR_TEAM,
    PERMISSION_ADMINISTRATOR_TEST_DATA,
    PERMISSION_ADMINISTRATOR_USER,
    PERMISSION_ADMINISTRATOR_USER_DISABLED,
    PERMISSION_CLAIM_DISPUTE,
    PERMISSION_CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE,
    PERMISSION_CLAIM_INVOICE,
    PERMISSION_CLAIM_RENUMERATION,
    PERMISSION_CLAIM_RENUMERATION_CHECK,
    PERMISSION_FLEET_IMPORT_DEVICES,
    PERMISSION_FLEET_MODEL,
    PERMISSION_FLEET_OVERVIEW,
    PERMISSION_FLEET_OVERVIEW_CHROME,
    PERMISSION_FLEET_OVERVIEW_VENDOR,
    PERMISSION_FLEET_OVERVIEW_WINDOW,
    PERMISSION_FLEET_SERIAL,
    PERMISSION_FLEET_SWAP,
    PERMISSION_ISSUE_BOM_OVERVIEW,
    PERMISSION_ISSUE_DISPATCH_CREATE,
    PERMISSION_ISSUE_DISPATCH_EDIT,
    PERMISSION_ISSUE_DISPATCH_IMPORT_EXPORT,
    PERMISSION_ISSUE_DISPATCH_RESUBMIT,
    PERMISSION_ISSUE_DISPATCH_VIEW,
    PERMISSION_ISSUE_OVERVIEW,
    PERMISSION_ISSUE_RACKSCANS,
    PERMISSION_ISSUE_RACKSCANS_SCAN,
    PERMISSION_ISSUE_RACKSCANS_TICKETS,
    PERMISSION_ISSUE_TICKETS,
    PERMISSION_KNOWLEDGE,
    PERMISSION_KNOWLEDGE_OVERVIEW,
    PERMISSION_PART_COMPONENT,
    PERMISSION_PART_PARTCODE,
    PERMISSION_PART_PRODUCT,
    PERMISSION_PART_PRODUCT_VIEW,
    PERMISSION_PROCUREMENT,
    PERMISSION_PROCUREMENT_DELIVERY,
    PERMISSION_PROCUREMENT_INVOICE,
    PERMISSION_PROCUREMENT_ORDER,
    PERMISSION_PROCUREMENT_ORDERCONFIRMATION,
    PERMISSION_PROCUREMENT_PURCHASEORDER,
    PERMISSION_PROCUREMENT_RMA,
    PERMISSION_PRODUCTION,
} from '~/constants/permissions'

const Navigator = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const redirectPath = localStorage.getItem('REDIRECT_URL')
        if (redirectPath) {
            localStorage.removeItem('REDIRECT_URL')
            navigate(redirectPath)
        }
    })

    return (
        <Layout>
            <Routes>
                <Route path={HOME} element={<Home />} />
                <Route path={DASHBOARD} element={<Home />} />
                <Route
                    path={ISSUE_OVERVIEW}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ISSUE_TICKETS]}
                        >
                            <TicketOverview />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ISSUE_TICKETS]}
                        >
                            <TicketDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_ISSUE}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ISSUE_OVERVIEW]}
                        >
                            <IssueOverview />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_BOM}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ISSUE_BOM_OVERVIEW]}
                        >
                            <BOMOverview />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_BOM_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ISSUE_BOM_OVERVIEW]}
                        >
                            <BOMPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_ISSUE_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ISSUE_OVERVIEW]}
                        >
                            <IssueDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_ISSUE_REPAIR}
                    element={<IssueRepairPage />}
                />
                <Route
                    path={ISSUE_ISSUE_QC}
                    element={<IssueQualityCheck issueData={{}} />}
                />
                <Route
                    path={ISSUE_DISPATCH}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ISSUE_DISPATCH_VIEW]}
                        >
                            <Dispatch />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_DISPATCH_COMPONENT}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ISSUE_DISPATCH_VIEW]}
                        >
                            <DispatchComponent />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_DISPATCH_IMPORT_EXPORT}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ISSUE_DISPATCH_IMPORT_EXPORT,
                            ]}
                        >
                            <DispatchesImportExport />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_DISPATCH_EDIT}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ISSUE_DISPATCH_EDIT,
                                PERMISSION_ISSUE_DISPATCH_RESUBMIT,
                            ]}
                        >
                            <EditDispatch />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_DISPATCH_ADD}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ISSUE_DISPATCH_CREATE]}
                        >
                            <AddDispatch />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_DISPATCH_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ISSUE_DISPATCH_VIEW]}
                        >
                            <DispatchDetailPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_RACKSCAN_TICKET}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ISSUE_RACKSCANS,
                                PERMISSION_ISSUE_RACKSCANS_TICKETS,
                            ]}
                        >
                            <Ticket />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_RACKSCAN_SCAN}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ISSUE_RACKSCANS,
                                PERMISSION_ISSUE_RACKSCANS_SCAN,
                            ]}
                        >
                            <Scan />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_RACKSCAN_SCAN_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ISSUE_RACKSCANS,
                                PERMISSION_ISSUE_RACKSCANS_SCAN,
                            ]}
                        >
                            <ScanDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ISSUE_RACKSCAN_SCAN_NEW}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ISSUE_RACKSCANS_SCAN]}
                        >
                            <ScanCreate />
                        </ProtectedRoute>
                    }
                />
                <Route path={ISSUE_ARCHIVE} element={<Placeholder />} />
                <Route path={ISSUE_ARCHIVE_IMAGE} element={<Placeholder />} />
                <Route
                    path={FLEET_OVERVIEW}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_FLEET_OVERVIEW]}
                        >
                            <FleetOverview />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={FLEET_OVERVIEW_VENDOR}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_FLEET_OVERVIEW_VENDOR]}
                        >
                            <VendorPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={FLEET_OVERVIEW_WINDOW}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_FLEET_OVERVIEW_WINDOW]}
                        >
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={FLEET_OVERVIEW_CHROME}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_FLEET_OVERVIEW_CHROME]}
                        >
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={FLEET_SERIAL}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_FLEET_SERIAL]}>
                            <SerialOverview />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={FLEET_SERIAL_DETAIL}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_FLEET_SERIAL]}>
                            <SerialPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={FLEET_SERIAL_DETAIL_SEARCH}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_FLEET_SERIAL]}>
                            <SerialPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={FLEET_IMPORT_DEVICES}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_FLEET_IMPORT_DEVICES]}
                        >
                            <ImportDevicesPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={FLEET_MODEL}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_FLEET_MODEL]}>
                            <Model />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={FLEET_MODEL_DETAIL}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_FLEET_MODEL]}>
                            <FleetModelDetails />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={FLEET_SWAP}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_FLEET_SWAP]}>
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route path={PART} element={<Placeholder />} />
                <Route
                    path={PART_COMPONENT}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_PART_COMPONENT]}
                        >
                            <Component />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PART_COMPONENT_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_PART_COMPONENT]}
                        >
                            <ComponentDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PART_USAGE}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_PART_COMPONENT]}
                        >
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PART_PARTCODE}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_PART_PARTCODE]}
                        >
                            <PartCode />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={KNOWLEDGE}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_KNOWLEDGE,
                                PERMISSION_KNOWLEDGE_OVERVIEW,
                            ]}
                        >
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={CLAIM}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_CLAIM_RENUMERATION]}
                        >
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={CLAIM_RENUMERATION}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_CLAIM_RENUMERATION,
                                PERMISSION_CLAIM_RENUMERATION_CHECK,
                            ]}
                        >
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={CLAIM_INVOICE}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_CLAIM_INVOICE]}
                        >
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={CLAIM_DISPUTE}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_CLAIM_DISPUTE]}
                        >
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE,
                            ]}
                        >
                            <ImportRemittancePage />
                        </ProtectedRoute>
                    }
                />
                <Route path={PROCUREMENT} element={<Placeholder />} />
                <Route
                    path={PROCUREMENT_PURCHASEORDER}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_PROCUREMENT_PURCHASEORDER]}
                        >
                            <PurchaseOrder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PROCUREMENT_PURCHASEORDER_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_PROCUREMENT_PURCHASEORDER]}
                        >
                            <PurchaseOrderDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PROCUREMENT_PURCHASEORDER_LINE_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_PROCUREMENT_PURCHASEORDER]}
                        >
                            <PurchaseOrderLine />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PROCUREMENT_ORDERS}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_PROCUREMENT_ORDER,
                                PERMISSION_PROCUREMENT,
                            ]}
                        >
                            <ProcurementOrder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PROCUREMENT_ORDERCONFIRMATION}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_PROCUREMENT_ORDERCONFIRMATION,
                            ]}
                        >
                            <OrderConfirmation />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PROCUREMENT_INVOICE}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_PROCUREMENT_INVOICE]}
                        >
                            <Invoice />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PROCUREMENT_DELIVERY}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_PROCUREMENT_DELIVERY]}
                        >
                            <Delivery />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PROCUREMENT_DELIVERY_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_PROCUREMENT_DELIVERY]}
                        >
                            <DeliveryDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PROCUREMENT_RMA}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_PROCUREMENT_RMA]}
                        >
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route path={PRODUCTION} element={<Placeholder />} />
                <Route
                    path={PRODUCTION_SYSTEMIMAGE}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_PRODUCTION]}>
                            <ImagesOverview />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PRODUCTION_IMAGES_DETAIL}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_PRODUCTION]}>
                            <ImageDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PRODUCTION_ORGANIZATION}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_PRODUCTION]}>
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PRODUCTION_PROJECTS}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_PRODUCTION_SERVERS,
                            ]}
                        >
                            <ProjectOverview />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PRODUCTION_PROJECTS_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_PRODUCTION_SERVERS,
                            ]}
                        >
                            <ProjectDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PRODUCTION_IMPORT_PROJECTS}
                    element={<ImportProject />}
                />
                <Route
                    path={PRODUCTION_ORDERS}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_PRODUCTION]}>
                            <OrdersOverview />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PRODUCTION_ORDERS_DETAIL}
                    element={
                        <ProtectedRoute permissions={[PERMISSION_PRODUCTION]}>
                            <OrderDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PRODUCTION_ADD_ONS}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_PRODUCTION_ADD_ONS,
                            ]}
                        >
                            <AddonsOverview />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PRODUCTION_ADD_ONS_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_PRODUCTION_ADD_ONS,
                            ]}
                        >
                            <AddonDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PRODUCTION_SERVERS}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_PRODUCTION_SERVERS,
                            ]}
                        >
                            <ServersOverview />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PRODUCTION_SERVERS_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_PRODUCTION_SERVERS,
                            ]}
                        >
                            <ServersDetail />
                        </ProtectedRoute>
                    }
                />
                <Route path={ADMINISTRATOR} element={<Placeholder />} />
                <Route
                    path={ADMINISTRATOR_USER}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR,
                                PERMISSION_ADMINISTRATOR_USER,
                            ]}
                        >
                            <User />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_USER_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR,
                                PERMISSION_ADMINISTRATOR_USER,
                            ]}
                        >
                            <UserDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_USER_DISABLED}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_USER_DISABLED,
                            ]}
                        >
                            <DisabledUser />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_USER_DISABLED_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_USER_DISABLED,
                            ]}
                        >
                            <UserDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_ROLE}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_ROLE]}
                        >
                            <Role />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_ROLE_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_ROLE]}
                        >
                            <RoleDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_ROLE_CREATE}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_ROLE_CREATE]}
                        >
                            <RoleCreate />
                        </ProtectedRoute>
                    }
                />
                <Route path={ADMINISTRATOR_SYSTEM} element={<Placeholder />} />
                <Route
                    path={ADMINISTRATOR_SYSTEM_ERRORLOG}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_SYSTEM_ERRORLOG,
                            ]}
                        >
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_SYSTEM_API}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_SYSTEM_API]}
                        >
                            <Placeholder />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
                            ]}
                        >
                            <ServiceAccount />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
                            ]}
                        >
                            <ServiceAccountDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT_CREATE}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
                            ]}
                        >
                            <ServiceAccountCreate />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION}
                    element={<Placeholder />}
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_REGIONS}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_REGION]}
                        >
                            <Region />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_REGIONS_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_REGION]}
                        >
                            <RegionDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_REGIONS_NEW}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_REGION]}
                        >
                            <RegionCreate />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_REGIONS_EDIT}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_REGION]}
                        >
                            <RegionEdit />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_SERVICECENTER}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER,
                            ]}
                        >
                            <ServiceCenter />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_SERVICECENTER_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER,
                            ]}
                        >
                            <ServiceCenterDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_SERVICECENTER_NEW}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER,
                            ]}
                        >
                            <ServiceCenterCreate />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_SERVICECENTER_EDIT}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER,
                            ]}
                        >
                            <ServiceCenterEdit />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_TEAM}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_TEAM]}
                        >
                            <Team />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_TEAM_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_TEAM]}
                        >
                            <TeamDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_TEAM_EDIT}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_TEAM]}
                        >
                            <TeamEdit />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_TEAM_NEW}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_TEAM]}
                        >
                            <TeamCreate />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_WORKBENCH}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DEFINITION_WORKBENCH,
                            ]}
                        >
                            <WorkBench />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_WORKBENCH_NEW}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DEFINITION_WORKBENCH,
                            ]}
                        >
                            <WorkbenchCreate />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_WORKBENCH_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DEFINITION_WORKBENCH,
                            ]}
                        >
                            <WorkbenchEdit />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_CAUSE}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DEFINITION,
                                PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE,
                            ]}
                        >
                            <Cause />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_CAUSE_NEW}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE_CREATE,
                            ]}
                        >
                            <CauseCreate />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DEFINITION_CAUSE_EDIT}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DEFINITION,
                                PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE,
                            ]}
                        >
                            <CauseEdit />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PART_PRODUCT}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_PART_PRODUCT,
                                PERMISSION_PART_PRODUCT_VIEW,
                            ]}
                        >
                            <Product />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={PART_PRODUCT_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_PART_PRODUCT,
                                PERMISSION_PART_PRODUCT_VIEW,
                            ]}
                        >
                            <ProductDetail />
                        </ProtectedRoute>
                    }
                />
                <Route path={PART_DELL_STOCK} element={<DellStock />} />
                <Route
                    path={ADMINISTRATOR_NAMING_TAG}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_NAMING_TAG]}
                        >
                            <NamingTag />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_NAMING_TAG_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_NAMING_TAG]}
                        >
                            <NamingTagDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_NAMING_TAG_CREATE}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_NAMING_TAG]}
                        >
                            <NamingTagCreate />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_NAMING_TAG_RULE_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_NAMING_TAG]}
                        >
                            <NamingTagRuleDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_NAMING_TAG_RULE_CREATE}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_NAMING_TAG]}
                        >
                            <NamingTagRuleCreate />
                        </ProtectedRoute>
                    }
                />
                <Route path={ADMINISTRATOR_TOOLTIP} element={<Tooltip />} />
                <Route
                    path={ADMINISTRATOR_TOOLTIP_DETAIL}
                    element={<TooltipDetail />}
                />
                <Route
                    path={ADMINISTRATOR_TOOLTIP_CREATE}
                    element={<TooltipCreate />}
                />
                <Route path={USER_SETTING} element={<UserSetting />} />
                {/* DataScheduler must be modify the permission after this issue is merged
                    https://codice.easy4u.cloud/device/device/-/issues/1143
                */}
                <Route
                    path={ADMINISTRATOR_DATASCHEDULER}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DATASCHEDULER,
                            ]}
                        >
                            <DataScheduler />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DATASCHEDULER_NEW}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DATASCHEDULER_CREATE,
                            ]}
                        >
                            <DataSchedulerCreate />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_DATASCHEDULER_DETAIL}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_DATASCHEDULER_EDIT,
                            ]}
                        >
                            <DataSchedulerEdit />
                        </ProtectedRoute>
                    }
                />

                <Route path='*' element={<Navigate to={HOME} />} />
                <Route path={DIAGNOSTIC} element={<Diagnostic />} />
                <Route
                    path={DIAGNOSTIC_DETAIL}
                    element={<DiagnosticDetail />}
                />
                <Route
                    path={ADMINISTRATOR_TEST_DATA_DIAGNOSE}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_TEST_DATA]}
                        >
                            <TestDataCreateDiagnose />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ADMINISTRATOR_TEST_DATA_DISPATCH_SHIPMENT_REPORT}
                    element={
                        <ProtectedRoute
                            permissions={[PERMISSION_ADMINISTRATOR_TEST_DATA]}
                        >
                            <TestDataIssues />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={ADMINISTRATOR_ACTIVITY_LOGS}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_ACTIVITY_LOG,
                                PERMISSION_ADMINISTRATOR,
                            ]}
                        >
                            <ActivityLogs />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={ADMINISTRATOR_ACTIVITY_LOGS_DETAILS}
                    element={
                        <ProtectedRoute
                            permissions={[
                                PERMISSION_ADMINISTRATOR_ACTIVITY_LOG,
                                PERMISSION_ADMINISTRATOR,
                            ]}
                        >
                            <ActivityLogsDetails />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Layout>
    )
}

export default Navigator
