import { Box, LinearProgress, Typography } from '@mui/material'
import StyledModal from '~/components/StyledModal'
import { useParams } from 'react-router-dom'
import { useIssueQuery } from '../query'
import PartTable from './PartTable'
import RepairPage from './RepairPage'
import { useEffect, useMemo, useState } from 'react'
import RemovedPartTable from './RemovedPartTable'
import { useQueryClient } from 'react-query'
import PropTypes from 'prop-types'
import { useIssuePartCodeQuery } from '~/pages/Diagnostic/query'
import { OWN_STOCK } from '~/constants/constants'
import Search from '~/components/Search/Search'
import { useTranslation } from 'react-i18next'
import { useScanMotherboardSerialMutation } from '~/pages/Issue/mutation'
import { parseApiErrorMessage } from '~/utils/helpers'
import ErrorMessageInput from '~/components/ErrorMessageInput'
import {
    ASSIGNED_TO_SUPERVISOR,
    CANCELLED,
} from '~/pages/Issue/Overview/constants'

const IssueRepairPage = ({ openScanReceivedPart, setOpenScanReceivedPart }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.issues.repair',
    })
    const queryClient = useQueryClient()
    const { issueId } = useParams()

    const { mutate: scanMotherboardSerial, isLoading: isScanning } =
        useScanMotherboardSerialMutation(issueId)

    const {
        data,
        isLoading: isQueryLoading,
        isSuccess,
    } = useIssueQuery(issueId)
    const { data: issuePartCodes } = useIssuePartCodeQuery(issueId, true, false)
    const hasOwnStockMBD = useMemo(() => {
        if (issuePartCodes?.length > 0) {
            return issuePartCodes.some(
                (partCode) =>
                    partCode.code === 'MBD' &&
                    partCode.stock_label === OWN_STOCK,
            )
        }
        return false
    }, [issuePartCodes])

    const currStep = useMemo(() => {
        return data?.data?.repair?.current_step ?? 0
    }, [data?.data?.repair?.current_step])

    const isValidState = useMemo(
        () => ![CANCELLED, ASSIGNED_TO_SUPERVISOR].includes(data?.data?.state),
        [data?.data?.state],
    )

    const [openRemovePart, setOpenRemovePart] = useState(false)

    const [openScanMotherboardSerial, setOpenScanMotherboardSerial] =
        useState(false)

    const [isMotherboardScanned, setIsMotherboardScanned] = useState(false)

    useEffect(() => {
        setOpenScanMotherboardSerial(
            hasOwnStockMBD &&
                !isMotherboardScanned &&
                currStep === 3 &&
                !data?.data?.repair?.finished_at &&
                isValidState,
        )
    }, [
        currStep,
        data?.data?.repair?.finished_at,
        hasOwnStockMBD,
        isMotherboardScanned,
        isValidState,
    ])

    useEffect(
        () =>
            setOpenRemovePart(
                isMotherboardScanned &&
                    currStep === 3 &&
                    !data?.data?.repair?.finished_at,
            ),
        [currStep, data?.data?.repair?.finished_at, isMotherboardScanned],
    )

    const [isInitialized, setIsInitialized] = useState(true)
    const [techComment, setTechComment] = useState('')
    const [scanErrorMsg, setScanErrorMsg] = useState('')

    useEffect(() => {
        if (isSuccess) {
            setTechComment(data?.data?.repair?.technician_comment ?? '')
        }
    }, [isSuccess, data])
    useEffect(() => {
        if (
            ['PARTS_READY', 'START_REPAIR'].includes(data?.data?.state) &&
            isInitialized
        ) {
            queryClient.removeQueries('part_issue')
            queryClient.removeQueries('issue')
            setIsInitialized(false)
        }
    }, [data, isInitialized, queryClient])

    const handleEnterSearch = (event) => {
        if (
            event.key === 'Enter' &&
            event.target.value &&
            event.target.value !== ''
        ) {
            scanMotherboardSerial(
                { serial: event.target.value },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries([
                            'is_scanned_motherboard_serial',
                            issueId,
                        ])
                        queryClient.invalidateQueries(['issue', issueId])
                        queryClient.invalidateQueries([
                            'ticket_details',
                            issueId,
                        ])
                        setIsMotherboardScanned(true)
                    },
                    onError: (e) => {
                        setScanErrorMsg(parseApiErrorMessage(e))
                    },
                },
            )
        }
    }

    if (isQueryLoading) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        )
    }

    return (
        <>
            <>
                {(currStep === 2 || currStep === 3) && (
                    <RepairPage
                        issueId={issueId}
                        issueData={data}
                        diagnosticId={data?.data?.diagnostic?.id}
                        finished={
                            !!data?.data?.repair?.finished_at || !isValidState
                        }
                        setOpenRemovePart={setOpenRemovePart}
                        techComment={techComment}
                        setTechComment={setTechComment}
                    />
                )}
            </>

            <StyledModal
                open={currStep === 1 && openScanReceivedPart === true}
                handleClose={() => setOpenScanReceivedPart(false)}
                stackProps={{ alignItems: 'unset', width: 850 }}
            >
                <Typography variant='h4' sx={{ mb: 2 }}>
                    {t('popups.scanReceivedParts')}
                </Typography>
                <PartTable
                    issueId={issueId}
                    setOpenScanReceivedPart={setOpenScanReceivedPart}
                    ppidRegex={
                        data?.data?.ticket?.device?.vendor
                            ?.ppid_validation_regex
                    }
                />
            </StyledModal>

            <StyledModal
                open={openRemovePart}
                handleClose={() => setOpenRemovePart(false)}
                stackProps={{ alignItems: 'unset', width: 1100 }}
            >
                <Typography variant='h4' sx={{ mb: 2 }}>
                    {t('popups.scanRemovedParts')}
                </Typography>
                <RemovedPartTable
                    {...{ issueId, setOpenRemovePart, techComment }}
                />
            </StyledModal>

            <StyledModal
                open={openScanMotherboardSerial}
                handleClose={() => setOpenScanMotherboardSerial(false)}
                stackStyles={{ width: 'auto' }}
            >
                <Search
                    label={t('scanMotherboardSerial')}
                    autoFocus
                    onKeyPress={handleEnterSearch}
                />
                {isScanning && (
                    <Box sx={{ width: '50%' }}>
                        <LinearProgress />
                    </Box>
                )}
                {scanErrorMsg !== '' && (
                    <ErrorMessageInput>{scanErrorMsg}</ErrorMessageInput>
                )}
            </StyledModal>
        </>
    )
}

IssueRepairPage.propTypes = {
    openScanReceivedPart: PropTypes.bool,
    setOpenScanReceivedPart: PropTypes.func,
}

export default IssueRepairPage
