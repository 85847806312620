import { TableBody, TableHead, TableRow } from '@mui/material'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DIAGNOSTIC } from '~/constants/Routes'
import TableCell from '~/pages/Diagnostic/components/TableCell'
import StyledTableRow from '~/pages/Diagnostic/components/StyledTableRow'
import ComponentTooltip from '~/components/ComponentTooltip'
import {
    ACCESSORY_INCLUDED,
    COSMETIC_DAMAGE,
    LIQUID_DAMAGE,
    POWERS_ON,
    SOFTWARE_ISSUE,
} from '~/pages/Diagnostic/constants'
import { Field } from 'react-final-form'
import StyledRadio from '~/pages/Diagnostic/components/StyledRadio'

const TriageCheckboxesSection = ({ values, isTriageDone }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'pages.diagnostic',
    })
    const checkBoxes = [
        COSMETIC_DAMAGE,
        ACCESSORY_INCLUDED,
        POWERS_ON,
        SOFTWARE_ISSUE,
        LIQUID_DAMAGE,
    ]

    return (
        <TableContainer sx={{ borderTop: '0.5px solid lightgray' }}>
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                        <TableCell
                            sx={{ border: '0.5px solid lightgray !important' }}
                        >
                            {t('device')}
                        </TableCell>
                        <TableCell
                            sx={{ border: '0.5px solid lightgray !important' }}
                        >
                            {t('yes')}
                        </TableCell>
                        <TableCell
                            sx={{ border: '0.5px solid lightgray !important' }}
                        >
                            {t('no')}
                        </TableCell>
                        <TableCell
                            sx={{ border: '0.5px solid lightgray !important' }}
                        >
                            N/A
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {checkBoxes.map((item) => (
                        <StyledTableRow key={item}>
                            <TableCell>
                                <ComponentTooltip
                                    componentId={`diagnostics_device_information_${item}`}
                                    pagePath={DIAGNOSTIC}
                                    placement='top'
                                >
                                    <div>{t(`${item}`)}</div>
                                </ComponentTooltip>
                            </TableCell>
                            <CheckboxesCells
                                {...{
                                    name: item,
                                    values,
                                    isTriageDone,
                                }}
                            />
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

TriageCheckboxesSection.propTypes = {
    values: PropTypes.object,
    isTriageDone: PropTypes.bool,
}

const CheckboxesCells = ({ name, values, isTriageDone }) => {
    return (
        <>
            <TableCell>
                <Field
                    disabled={isTriageDone}
                    name={name}
                    type='radio'
                    value={'true'}
                    checked={
                        values[name] === 'true' ||
                        values[name] === true ||
                        values[name] === 1
                    }
                    component={StyledRadio}
                />
            </TableCell>
            <TableCell>
                <Field
                    disabled={isTriageDone}
                    name={name}
                    type='radio'
                    value={'false'}
                    checked={
                        values[name] === 'false' ||
                        values[name] === false ||
                        values[name] === 0
                    }
                    component={StyledRadio}
                />
            </TableCell>
            <TableCell>
                {name === SOFTWARE_ISSUE && (
                    <Field
                        disabled={isTriageDone}
                        name={name}
                        type='radio'
                        value={'n/a'}
                        checked={values[name] === 'n/a' || values[name] === -1}
                        component={StyledRadio}
                    />
                )}
            </TableCell>
        </>
    )
}

CheckboxesCells.propTypes = {
    name: PropTypes.string,
    values: PropTypes.object,
    isTriageDone: PropTypes.bool,
}

export default TriageCheckboxesSection
