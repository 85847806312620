import { useSelector } from 'react-redux'
import {
    ADMINISTRATOR_USER_IMPERSONATE,
    PERMISSION_ADMINISTRATOR,
    PERMISSION_ADMINISTRATOR_DATASCHEDULER,
    PERMISSION_ADMINISTRATOR_DATASCHEDULER_CREATE,
    PERMISSION_ADMINISTRATOR_DATASCHEDULER_EDIT,
    PERMISSION_ADMINISTRATOR_DATASCHEDULER_GENERAL,
    PERMISSION_ADMINISTRATOR_DATASCHEDULER_VIEW,
    PERMISSION_ADMINISTRATOR_DEFINITION,
    PERMISSION_ADMINISTRATOR_DEFINITION_ADDRESSES_CREATE,
    PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE,
    PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE_CREATE,
    PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER,
    PERMISSION_ADMINISTRATOR_DEFINITION_WORKBENCH,
    PERMISSION_ADMINISTRATOR_NAMING_TAG,
    PERMISSION_ADMINISTRATOR_PRODUCTION_ADD_ONS,
    PERMISSION_ADMINISTRATOR_PRODUCTION_SERVERS,
    PERMISSION_ADMINISTRATOR_REGION,
    PERMISSION_ADMINISTRATOR_ROLE,
    PERMISSION_ADMINISTRATOR_ROLE_CREATE,
    PERMISSION_ADMINISTRATOR_SYSTEM,
    PERMISSION_ADMINISTRATOR_SYSTEM_API,
    PERMISSION_ADMINISTRATOR_SYSTEM_ERRORLOG,
    PERMISSION_ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
    PERMISSION_ADMINISTRATOR_TEAM,
    PERMISSION_ADMINISTRATOR_TEST_DATA,
    PERMISSION_ADMINISTRATOR_USER,
    PERMISSION_ADMINISTRATOR_USER_DISABLED,
    PERMISSION_CLAIM_DISPUTE,
    PERMISSION_CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE,
    PERMISSION_CLAIM_INVOICE,
    PERMISSION_CLAIM_RENUMERATION,
    PERMISSION_CLAIM_RENUMERATION_CHECK,
    PERMISSION_DASHBOARD,
    PERMISSION_FLEET_IMPORT_DEVICES,
    PERMISSION_FLEET_MODEL,
    PERMISSION_FLEET_OVERVIEW,
    PERMISSION_FLEET_OVERVIEW_CHROME,
    PERMISSION_FLEET_OVERVIEW_VENDOR,
    PERMISSION_FLEET_OVERVIEW_WINDOW,
    PERMISSION_FLEET_SERIAL,
    PERMISSION_FLEET_SERIAL_ADD,
    PERMISSION_FLEET_SERIAL_UPDATE,
    PERMISSION_FLEET_SWAP,
    PERMISSION_ISSUE_BOM_EDIT,
    PERMISSION_ISSUE_BOM_EDIT_STOCK_LABEL,
    PERMISSION_ISSUE_BOM_OVERVIEW,
    PERMISSION_ISSUE_CLOSE,
    PERMISSION_ISSUE_DIAGNOSTICS_ACCIDENTAL_DAMAGE,
    PERMISSION_ISSUE_DIAGNOSTICS_ADD_ADDITIONAL_PARTS,
    PERMISSION_ISSUE_DIAGNOSTICS_MALICIOUS_DAMAGE,
    PERMISSION_ISSUE_DISPATCH_CREATE,
    PERMISSION_ISSUE_DISPATCH_EDIT,
    PERMISSION_ISSUE_DISPATCH_IMPORT_EXPORT,
    PERMISSION_ISSUE_DISPATCH_REQUEST_TECHNICIAN_ON_SITE,
    PERMISSION_ISSUE_DISPATCH_RESUBMIT,
    PERMISSION_ISSUE_DISPATCH_VIEW,
    PERMISSION_ISSUE_EXPERT,
    PERMISSION_ISSUE_IMAGES_CANCEL,
    PERMISSION_ISSUE_IMAGES_PROCEED,
    PERMISSION_ISSUE_OVERVIEW,
    PERMISSION_ISSUE_PART_DELIVERED,
    PERMISSION_ISSUE_QC_OVERRULE,
    PERMISSION_ISSUE_RACKSCANS,
    PERMISSION_ISSUE_RACKSCANS_SCAN,
    PERMISSION_ISSUE_RACKSCANS_TICKETS,
    PERMISSION_ISSUE_TICKETS,
    PERMISSION_KNOWLEDGE,
    PERMISSION_KNOWLEDGE_OVERVIEW,
    PERMISSION_PART,
    PERMISSION_PART_COMPONENT,
    PERMISSION_PART_PARTCODE,
    PERMISSION_PART_PRODUCT,
    PERMISSION_PART_PRODUCT_LINK_COMPONENTS,
    PERMISSION_PART_PRODUCT_VIEW,
    PERMISSION_PROCUREMENT,
    PERMISSION_PROCUREMENT_DELIVERY,
    PERMISSION_PROCUREMENT_INVOICE,
    PERMISSION_PROCUREMENT_ORDER,
    PERMISSION_PROCUREMENT_ORDERCONFIRMATION,
    PERMISSION_PROCUREMENT_PURCHASEORDER,
    PERMISSION_PROCUREMENT_RMA,
    PERMISSION_PRODUCTION,
    SUPER_ADMIN,
} from '~/constants/permissions'
import { selectUserPermissions } from '~/store/auth/selector'

const usePermissions = () => {
    const userPermissions = useSelector(selectUserPermissions)

    const hasPermissions = (requiredPermissions, canAny = true) => {
        if (userPermissions.includes(SUPER_ADMIN)) {
            return true
        }

        if (Array.isArray(requiredPermissions)) {
            return canAny
                ? requiredPermissions.some((permission) =>
                      userPermissions.includes(permission),
                  )
                : requiredPermissions.every((permission) =>
                      userPermissions.includes(permission),
                  )
        }

        return userPermissions.includes(requiredPermissions)
    }

    const permissions = {
        isSuperAdmin: hasPermissions(SUPER_ADMIN),
        dashBoard: hasPermissions(PERMISSION_DASHBOARD),
        issue: {
            canViewTicket: hasPermissions([PERMISSION_ISSUE_TICKETS]),
            canViewOIssueverview: hasPermissions([PERMISSION_ISSUE_OVERVIEW]),
            canViewBOM: hasPermissions([PERMISSION_ISSUE_BOM_OVERVIEW]),
            canViewDispatch: hasPermissions([PERMISSION_ISSUE_DISPATCH_VIEW]),
            canImportExportDispatch: hasPermissions([
                PERMISSION_ISSUE_DISPATCH_IMPORT_EXPORT,
            ]),
            canRequestTechnicianOnSite: hasPermissions([
                PERMISSION_ISSUE_DISPATCH_REQUEST_TECHNICIAN_ON_SITE,
            ]),
            caneditReSubmittDispatch: hasPermissions([
                PERMISSION_ISSUE_DISPATCH_EDIT,
                PERMISSION_ISSUE_DISPATCH_RESUBMIT,
            ]),
            canCloseIssue: hasPermissions([PERMISSION_ISSUE_CLOSE]),
            canManageExpertIssue: hasPermissions([PERMISSION_ISSUE_EXPERT]),
            canManagePartDelivered: hasPermissions([
                PERMISSION_ISSUE_PART_DELIVERED,
            ]),
            canOverruleQC: hasPermissions(
                [PERMISSION_ISSUE_QC_OVERRULE, PERMISSION_ISSUE_EXPERT],
                false,
            ),
            diagnostic: {
                canCheckAccidentalDamge: hasPermissions([
                    PERMISSION_ISSUE_DIAGNOSTICS_ACCIDENTAL_DAMAGE,
                ]),
                canCheckMaliciousDamge: hasPermissions([
                    PERMISSION_ISSUE_DIAGNOSTICS_MALICIOUS_DAMAGE,
                ]),
                canAddAdditionalParts: hasPermissions([
                    PERMISSION_ISSUE_DIAGNOSTICS_ADD_ADDITIONAL_PARTS,
                ]),
            },
            bom: {
                canViewBom: hasPermissions([PERMISSION_ISSUE_BOM_OVERVIEW]),
                canEditBom: hasPermissions([PERMISSION_ISSUE_BOM_EDIT]),
                canEditBomStockLabel: hasPermissions([
                    PERMISSION_ISSUE_BOM_EDIT_STOCK_LABEL,
                ]),
            },
            dispatch: {
                canCreateDispatch: hasPermissions([
                    PERMISSION_ISSUE_DISPATCH_CREATE,
                ]),
            },
            images: {
                canCancelImageIssue: hasPermissions([
                    PERMISSION_ISSUE_IMAGES_CANCEL,
                ]),
                canProceedImageIssue: hasPermissions([
                    PERMISSION_ISSUE_IMAGES_PROCEED,
                ]),
            },
            rackScan: {
                canCreateScan: hasPermissions([
                    PERMISSION_ISSUE_RACKSCANS_SCAN,
                ]),
                canViewRackScanAndRackTicket: hasPermissions([
                    PERMISSION_ISSUE_RACKSCANS,
                    PERMISSION_ISSUE_RACKSCANS_TICKETS,
                ]),
                canViewRackScan: hasPermissions([
                    PERMISSION_ISSUE_RACKSCANS,
                    PERMISSION_ISSUE_RACKSCANS_SCAN,
                ]),
            },
        },
        parts: {
            canViewPart: hasPermissions([
                PERMISSION_PART,
                PERMISSION_PART_COMPONENT,
            ]),
            canViewPartComponent: hasPermissions([PERMISSION_PART_COMPONENT]),
            canViewPartCodes: hasPermissions([PERMISSION_PART_PARTCODE]),
            products: {
                canLinkComponent: hasPermissions([
                    PERMISSION_PART_PRODUCT_LINK_COMPONENTS,
                ]),
                canView: hasPermissions([
                    PERMISSION_PART_PRODUCT,
                    PERMISSION_PART_PRODUCT_VIEW,
                ]),
            },
        },
        knowledge: {
            canView: hasPermissions([
                PERMISSION_KNOWLEDGE,
                PERMISSION_KNOWLEDGE_OVERVIEW,
            ]),
        },
        fleet: {
            canViewFleet: hasPermissions([PERMISSION_FLEET_OVERVIEW]),
            canViewFleetVendor: hasPermissions([
                PERMISSION_FLEET_OVERVIEW_VENDOR,
            ]),
            canViewFleetWindow: hasPermissions([
                PERMISSION_FLEET_OVERVIEW_WINDOW,
            ]),
            canViewFleetChrome: hasPermissions([
                PERMISSION_FLEET_OVERVIEW_CHROME,
            ]),
            canViewFleetSerial: hasPermissions([PERMISSION_FLEET_SERIAL]),
            canAddSerials: hasPermissions([PERMISSION_FLEET_SERIAL_ADD]),
            canUpdateSerials: hasPermissions([PERMISSION_FLEET_SERIAL_UPDATE]),
            canImportDevice: hasPermissions([PERMISSION_FLEET_IMPORT_DEVICES]),
            canViewModels: hasPermissions([PERMISSION_FLEET_MODEL]),
            canFleetSwap: hasPermissions([PERMISSION_FLEET_SWAP]),
        },
        claim: {
            canView: hasPermissions([PERMISSION_CLAIM_RENUMERATION]),
            canClaimInvoice: hasPermissions([PERMISSION_CLAIM_INVOICE]),
            canClaimDispute: hasPermissions([PERMISSION_CLAIM_DISPUTE]),
            canClaimDisputeImportRemittance: hasPermissions([
                PERMISSION_CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE,
            ]),
            canCheck: hasPermissions([
                PERMISSION_CLAIM_RENUMERATION,
                PERMISSION_CLAIM_RENUMERATION_CHECK,
            ]),
        },
        procument: {
            canProcumentPurchase: hasPermissions([
                PERMISSION_PROCUREMENT_PURCHASEORDER,
            ]),
            canProcumentOrder: hasPermissions([
                PERMISSION_PROCUREMENT_ORDER,
                PERMISSION_PROCUREMENT,
            ]),
            canConfirmOrder: hasPermissions([
                PERMISSION_PROCUREMENT_ORDERCONFIRMATION,
            ]),
            canViewInvoice: hasPermissions([PERMISSION_PROCUREMENT_INVOICE]),
            canDelivery: hasPermissions([PERMISSION_PROCUREMENT_DELIVERY]),
            canViewRMA: hasPermissions([PERMISSION_PROCUREMENT_RMA]),
        },
        production: {
            canTakeActions: hasPermissions([PERMISSION_PRODUCTION]),
        },
        administrator: {
            namingTag: {
                canTakeActions: hasPermissions([
                    PERMISSION_ADMINISTRATOR_NAMING_TAG,
                ]),
            },
            canViewSystemApi: hasPermissions([
                PERMISSION_ADMINISTRATOR_SYSTEM_API,
            ]),
            canViewServiceAccount: hasPermissions([
                PERMISSION_ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
            ]),
            production: {
                canTakeActions: hasPermissions([
                    PERMISSION_ADMINISTRATOR_PRODUCTION_SERVERS,
                ]),
                canAddons: hasPermissions([
                    PERMISSION_ADMINISTRATOR_PRODUCTION_ADD_ONS,
                ]),
            },
            serviceCenter: {
                canEditServiceCenter: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER,
                ]),
            },
            definitions: {
                canCreateAddress: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DEFINITION_ADDRESSES_CREATE,
                ]),
                canCreateCauses: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE_CREATE,
                ]),
            },
            user: {
                canView: hasPermissions([
                    PERMISSION_ADMINISTRATOR,
                    PERMISSION_ADMINISTRATOR_USER,
                ]),
                canViewDisabled: hasPermissions([
                    PERMISSION_ADMINISTRATOR_USER_DISABLED,
                ]),
                canImpersonateUser: hasPermissions([
                    ADMINISTRATOR_USER_IMPERSONATE,
                ]),
            },
            role: {
                canView: hasPermissions([PERMISSION_ADMINISTRATOR_ROLE]),
                canCreate: hasPermissions([
                    PERMISSION_ADMINISTRATOR_ROLE_CREATE,
                ]),
            },
            serverErrorLog: {
                canView: hasPermissions([
                    PERMISSION_ADMINISTRATOR_SYSTEM,
                    PERMISSION_ADMINISTRATOR_SYSTEM_ERRORLOG,
                ]),
            },
            team: {
                canView: hasPermissions([PERMISSION_ADMINISTRATOR_TEAM]),
            },
            region: {
                canView: hasPermissions([PERMISSION_ADMINISTRATOR_REGION]),
            },
            workbench: {
                canView: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DEFINITION_WORKBENCH,
                ]),
            },
            servicecenter: {
                canView: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER,
                ]),
            },
            causes: {
                canView: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DEFINITION,
                    PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE,
                ]),
                canCreate: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE_CREATE,
                ]),
            },
            testData: {
                canView: hasPermissions([PERMISSION_ADMINISTRATOR_TEST_DATA]),
            },
            dataScheduler: {
                canViewMenu: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DATASCHEDULER,
                ]),
                canCreate: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DATASCHEDULER_CREATE,
                    PERMISSION_ADMINISTRATOR_DATASCHEDULER_GENERAL,
                ]),
                canEdit: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DATASCHEDULER_EDIT,
                    PERMISSION_ADMINISTRATOR_DATASCHEDULER_GENERAL,
                ]),
                canView: hasPermissions([
                    PERMISSION_ADMINISTRATOR_DATASCHEDULER_VIEW,
                    PERMISSION_ADMINISTRATOR_DATASCHEDULER_GENERAL,
                ]),
            },
        },
        procurement: {
            canViewProcument: hasPermissions([PERMISSION_PROCUREMENT]),
        },
    }

    return {
        permissions,
        hasPermissions,
    }
}

export default usePermissions
