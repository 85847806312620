import { Stack, Typography } from '@mui/material'
import PageLayout from '~/components/layout/PageLayout'
import {
    ISSUE_DISPATCH,
    ISSUE_DISPATCH_DETAIL,
    ISSUE_ISSUE,
} from '~/constants/Routes'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    clearSelectedPartCodeTypes,
    clearSelectedPartCodes,
    pushShowPartCodes,
} from '~/store/partcode/slice'
import CauseSection from '../AddDispatch/components/CauseSection'
import DescriptionSection from '../AddDispatch/components/DescriptionSection'
import DeviceSection from '../AddDispatch/components/DeviceSection'
import DispatchInfoSection from '../AddDispatch/components/DispatchInfoSection'
import PartCodeSection from '../AddDispatch/components/PartCodeSection'
import ServiceCenterSection from '../AddDispatch/components/ServiceCenterSection'
import { useDispatchDetailQuery } from '../DispatchDetail/query'
import { LoadingButton } from '~/components/Button'
import {
    selectSelectedPartCodes,
    selectShowedPartCode,
} from '~/store/partcode/selector'
import { useResubmitDispatchMutation } from './mutate'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { selectDevice } from '~/store/device/selector'
import { textValidateRegex } from '~/utils/helpers'

const EditDispatch = () => {
    const { id } = useParams()
    const { data: dispatchContent, isSuccess } = useDispatchDetailQuery(id)
    const refPartCodeSection = useRef(null)
    const [searchedSerial, setSearchedSerial] = useState('')
    const selectedPartCodes = useSelector(selectSelectedPartCodes)
    const { openSnackbar } = useDialogContext()
    const { mutate: resubmitDispatch, isLoading: isResubmitting } =
        useResubmitDispatchMutation()
    const navigate = useNavigate()

    const breadcrumbs = [
        { route: ISSUE_ISSUE, title: 'Issues' },
        { route: ISSUE_DISPATCH, title: 'Dispatches' },
        {
            route: ISSUE_DISPATCH_DETAIL.replace(':id', dispatchContent?.id),
            title: dispatchContent?.name,
            notTranlsate: true,
        },
        {
            page: 'edit_dispatch',
            title: 'Edit',
        },
    ]

    const dispatch = useDispatch()
    const [returnToDepot, setReturnToDepot] = useState(false)
    const [clickedCause, setClickedCause] = useState({})
    const [selectedCauses, setSelectedCauses] = useState([])
    const [isSelectedCausesChange, setIsSelectedCausesChange] = useState(false)
    const [initComp, setInitComp] = useState(true)
    const [removedParts, setRemovedParts] = useState({})
    const showingPartCode = useSelector(selectShowedPartCode)
    const device = useSelector(selectDevice)
    const ppidRegex = device?.vendor?.ppid_validation_regex ?? null

    const freshSelectedPartCodes = useCallback(() => {
        dispatch(clearSelectedPartCodes())
        dispatch(clearSelectedPartCodeTypes())
        dispatch(pushShowPartCodes())
    }, [dispatch])

    useEffect(() => {
        if (isSuccess) {
            setSearchedSerial(dispatchContent.serial)
            dispatch(pushShowPartCodes(dispatchContent.parts))
            setReturnToDepot(dispatchContent.request_return_to_depot)
        }
    }, [dispatch, dispatchContent, isSuccess])

    useEffect(() => {
        if (selectedCauses.length > 0) {
            setIsSelectedCausesChange(true)
        }
    }, [selectedCauses])

    const initValue = useMemo(() => {
        if (isSuccess) {
            let value = isSelectedCausesChange
                ? selectedCauses
                      .map((cause) => `${cause.name}: ${cause.notes}`)
                      .join('\n')
                      .trim()
                : dispatchContent?.troubleshooting_note

            const existingPartCodes = {}
            showingPartCode?.forEach((part) => {
                if (part?.code) {
                    existingPartCodes[part.code] = true
                }
            })
            const parts = Object.keys(removedParts).filter(
                (part) => !existingPartCodes[part],
            )
            if (parts.length > 0) {
                const warn =
                    'Please read!: The following part code(s) have been removed from the request: [x]. ' +
                    "Due to limitations of the API, we can't change the title of the dispatch to reflect the removal of these part codes.\n"
                value = warn.replace('[x]', parts.join(', ')) + value
            }

            return {
                request_on_site_tech: dispatchContent.request_on_site_tech,
                request_complete_care: dispatchContent.request_complete_care,
                request_return_to_depot:
                    dispatchContent.request_return_to_depot,
                ticket_id: dispatchContent.ticket_id,
                esd_location: dispatchContent.esd_location,
                service_center: dispatchContent.service_center_id,
                description_of_problem: dispatchContent.description_of_problem,
                troubleshooting_note: value,
                default_causes: dispatchContent.causes,
            }
        }
        return {}
    }, [
        removedParts,
        showingPartCode,
        isSuccess,
        selectedCauses,
        dispatchContent,
        isSelectedCausesChange,
    ])

    const onSubmit = (values) => {
        const payload = {
            dispatch_code: dispatchContent.dispatch_code,
            troubleshooting_note: values.troubleshooting_note,
            parts: Number.parseInt(values.request_return_to_depot)
                ? []
                : selectedPartCodes,
        }

        const missRequiredPPID = selectedPartCodes.some(
            (partCode) => partCode.serializable && partCode.ppid === '',
        )
        const maxAttempPPIDLength = selectedPartCodes.some(
            (partCode) =>
                partCode.ppid !== '' &&
                ppidRegex &&
                !textValidateRegex(ppidRegex, partCode.ppid),
        )

        if (missRequiredPPID || maxAttempPPIDLength) {
            refPartCodeSection.current?.scrollIntoView({
                behavior: 'smooth',
            })
            return false
        }

        resubmitDispatch(payload, {
            onSuccess: () => {
                openSnackbar({
                    message: 'Resubmit successful!',
                    type: 'success',
                })
                freshSelectedPartCodes()
                navigate(
                    ISSUE_DISPATCH_DETAIL.replace(':id', dispatchContent?.id),
                )
                window.scrollTo(0, 0)
            },
            onError: (data) => {
                let messageError = 'Resubmit failed!'
                if (data?.response?.data?.DELL_error) {
                    messageError =
                        data?.response?.data?.error_message ??
                        data?.response?.data?.notes
                }
                openSnackbar({
                    message: data?.response?.data?.message ?? messageError,
                    type: 'error',
                })
            },
        })
    }
    return (
        <>
            <PageLayout {...{ breadcrumbs }} pageName={'Edit dispatch'}>
                <Form
                    initialValues={{ ...initValue }}
                    onSubmit={onSubmit}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={2}>
                                <DeviceSection
                                    {...{
                                        searchedSerial,
                                        setIsFoundSerialData: () => {},
                                        isDispatchDetail: true,
                                        dispatch: dispatchContent,
                                    }}
                                />
                                <ServiceCenterSection show isInEditPage />
                                <DispatchInfoSection
                                    {...{
                                        show: true,
                                        values,
                                        setReturnToDepot,
                                        isInEditPage: true,
                                    }}
                                />
                                <CauseSection
                                    show={!returnToDepot}
                                    setSelectedCauses={setSelectedCauses}
                                    setClickedCause={setClickedCause}
                                    serial={dispatchContent?.serial}
                                    defaultCauses={initValue.default_causes}
                                    setRemovedParts={setRemovedParts}
                                    dispatchContent={dispatchContent}
                                />
                                <DescriptionSection
                                    show={true}
                                    isEditing={true}
                                />
                                <PartCodeSection
                                    show={!returnToDepot}
                                    clickedCause={clickedCause}
                                    serial={dispatchContent?.serial}
                                    initComp={initComp}
                                    setInitComp={setInitComp}
                                    setRemovedParts={setRemovedParts}
                                    innerRef={refPartCodeSection}
                                />

                                <LoadingButton
                                    label={'Resubmit'}
                                    type='submit'
                                    disabled={
                                        dispatchContent?.number_of_retries >= 3
                                    }
                                    loading={isResubmitting}
                                />
                                <Typography>
                                    Number of tries left:{' '}
                                    {3 - dispatchContent?.number_of_retries ??
                                        0}
                                </Typography>
                            </Stack>
                        </form>
                    )}
                />
            </PageLayout>
        </>
    )
}

export default EditDispatch
