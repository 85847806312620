import { useState } from 'react'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import DataNotFound from '~/components/DataNotFound'
import PropTypes from 'prop-types'

const headCells = [
    {
        id: 'file_name',
        label: 'File Name',
    },
]

const ImportRemittanceTable = ({ data }) => {
    const order = useState('asc')
    const orderBy = useState('')

    return (
        <Paper sx={{ width: '100%' }}>
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby='tableTitle'
                    size='medium'
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? 'center' : 'left'}
                                    padding={
                                        headCell.disablePadding
                                            ? 'none'
                                            : 'normal'
                                    }
                                    sortDirection={
                                        orderBy === headCell.id ? order : false
                                    }
                                    style={{
                                        top: 57,
                                        Width: headCells.maxWidth,
                                    }}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.length > 0 ? (
                            data.map((row, index) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={index}>
                                        <TableCell align='left'>
                                            {++index}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {row}
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        ) : (
                            <DataNotFound colSpan={12} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

ImportRemittanceTable.propTypes = { data: PropTypes.arrayOf(PropTypes.any) }

export default ImportRemittanceTable
