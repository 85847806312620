import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { useEffect } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import StyledRadio from '../../../Diagnostic/components/StyledRadio'
import TableCell from '../../../Diagnostic/components/TableCell'
import useStyles from '../../../Diagnostic/hooks/useStyles'
import { LOCALIZATION } from '../constants'
import Layout from './Section/Layout'
import StyledTableRow from './Table/StyledTableRow'
import PropTypes from 'prop-types'
import usePermissions from '~/hooks/usePermission'

const DispatchInfoSection = ({
    show,
    values,
    setReturnToDepot,
    isInEditPage = false,
}) => {
    const { permissions } = usePermissions()
    const { t } = useTranslation('translation', { keyPrefix: LOCALIZATION })
    const classes = useStyles()

    const canRequestTechnicianOnSite =
        permissions.issue.canRequestTechnicianOnSite

    const options = ['request_complete_care', 'request_return_to_depot']
    if (canRequestTechnicianOnSite) {
        options.push('request_on_site_tech')
    }

    useEffect(() => {
        setReturnToDepot(!!Number.parseInt(values.request_return_to_depot))
    }, [setReturnToDepot, values.request_return_to_depot])

    if (!show) return <></>

    return (
        <Layout>
            <Typography variant='h6' gutterBottom>
                {t('dispatch_info')}
            </Typography>
            <Table>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell>{t('options')}</TableCell>
                        <TableCell>{t('yes')}</TableCell>
                        <TableCell>{t('no')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {options.map((item) => (
                        <StyledTableRow key={item}>
                            <TableCell>
                                <div>{t(item)}</div>
                            </TableCell>
                            <CheckboxesCells
                                name={item}
                                values={values}
                                disabled={isInEditPage}
                            />
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </Layout>
    )
}

DispatchInfoSection.propTypes = {
    show: PropTypes.bool,
    values: PropTypes.object,
    setReturnToDepot: PropTypes.func,
    isInEditPage: PropTypes.bool,
}

const CheckboxesCells = ({ name, values, disabled = false }) => {
    const isYes = values[name] === true || parseInt(values[name]) === 1
    return (
        <>
            <TableCell>
                <Field
                    name={name}
                    type='radio'
                    value={1}
                    checked={isYes}
                    component={StyledRadio}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell>
                <Field
                    name={name}
                    type='radio'
                    value={0}
                    checked={!isYes}
                    component={StyledRadio}
                    disabled={disabled}
                />
            </TableCell>
        </>
    )
}

CheckboxesCells.propTypes = {
    name: PropTypes.string,
    values: PropTypes.object,
    disabled: PropTypes.bool,
}

export default DispatchInfoSection
