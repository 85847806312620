import userApi from '~/api/userApi'
import { useQuery } from 'react-query'
import { removeEmptyValue } from '~/utils/helpers'
import { DEFAULT_CACHE_TTL } from '~/utils/constants'

export const useUsersQuery = (params) => {
    const p = removeEmptyValue(params)
    return useQuery(
        ['users', params],
        async () => {
            const { data } = await userApi.getUsers(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: () => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useDisabledUsersQuery = (params) => {
    const p = removeEmptyValue(params)
    return useQuery(
        ['disabled_users', params],
        async () => {
            const { data } = await userApi.getDisabledUsers(p)
            return { data: data.data, total: data.meta?.total }
        },
        {
            onSuccess: (data) => {},
            onError: () => {},
            retry: false,
        },
    )
}

export const useUserStatesQuery = () => {
    return useQuery(
        ['user_states'],
        async () => {
            const { data } = await userApi.getUserStates()
            return data
        },
        {
            retry: false,
            cacheTime: DEFAULT_CACHE_TTL,
            staleTime: DEFAULT_CACHE_TTL,
        },
    )
}
