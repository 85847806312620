import TableFilters from '~/components/TableFilters'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const statuses = [
    { id: '', name: 'All' },
    { id: 'ACTIVE', name: 'ACTIVE' },
    { id: 'PENDING', name: 'PENDING' },
    { id: 'REJECTED', name: 'REJECTED' },
    { id: 'INVITED', name: 'INVITED' },
    { id: 'DISABLED', name: 'DISABLED' },
]

const UserTableFilters = ({
    search,
    searchHandler,
    state,
    setState,
    onEnterSearch,
}) => {
    const selectGroup = useMemo(() => {
        return [
            {
                label: 'Status',
                handleFunc: setState,
                dataSet: statuses,
                value: state,
            },
        ]
    }, [setState, state])
    return (
        <TableFilters
            {...{ selectGroup, search, searchHandler, onEnterSearch }}
            searchPlaceHolder='users'
            page='roles'
        />
    )
}
UserTableFilters.propTypes = {
    search: PropTypes.string,
    searchHandler: PropTypes.func,
    role: PropTypes.string,
    setRole: PropTypes.func,
    state: PropTypes.string,
    setState: PropTypes.func,
    onEnterSearch: PropTypes.func,
}
export default UserTableFilters
