import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {
    Box,
    Divider,
    Grid,
    Modal,
    Typography,
    Stack,
    Button,
} from '@mui/material'
import SubmitButton from '~/components/SubmitButton'
import { useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useQueryClient } from 'react-query'
import { useDialogContext } from '~/components/providers/StyledDialogContext'
import { useTranslation } from 'react-i18next'
import { UploadFile } from '@mui/icons-material'

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const ErrorBox = styled(Box)({
    maxHeight: '7rem',
    overflow: 'auto',
})

const ErrorTypography = styled(Typography)({
    color: '#d32f2f',
    fontFamily: "'Lato',sans-serif",
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
})

const UploadPopup = ({
    openUpload,
    setOpenUpload,
    actionImport,
    isLoading,
    acceptType,
    acceptTypeMessage,
}) => {
    const [selectedFile, setSelectedFile] = useState(false)
    const [failures, setFailures] = useState([])
    const [fileName, setFileName] = useState('')
    const [error, setError] = useState()
    const { openSnackbar } = useDialogContext()
    const { t } = useTranslation()

    const queryClient = useQueryClient()

    const changeHandler = useCallback((event) => {
        setSelectedFile(event.target.files[0])
        setFailures([])
        setError()
    }, [])

    const onSubmit = useCallback(() => {
        actionImport(selectedFile, {
            onSettled: () => {
                setSelectedFile(false)
                setFileName('')
            },
            onSuccess: (data) => {
                if (data.data.status === 'success') {
                    setOpenUpload(false)
                    queryClient.invalidateQueries('users')
                    openSnackbar({
                        message: t(`message.importSuccess`),
                        type: 'success',
                    })
                }
            },
            onError: (e) => {
                setFailures(e.response.data.data)
                setError(e.response.data.message)
                openSnackbar({
                    message: t(`message.importFailed`),
                    type: 'error',
                })
            },
        })
    }, [
        actionImport,
        queryClient,
        selectedFile,
        setOpenUpload,
        openSnackbar,
        t,
    ])

    const handleClose = () => {
        setOpenUpload(false)
        setFailures([])
        setError()
    }

    return (
        <>
            <StyledModal open={openUpload} onClose={(e) => handleClose()}>
                <Box
                    bgcolor={'background.default'}
                    color={'text.primary'}
                    p={3}
                    borderRadius={5}
                    maxWidth={700}
                    minWidth={600}
                >
                    <Typography variant='h6' color='gray' textAlign='center'>
                        {t(`general.importFile`)}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Form
                        onSubmit={onSubmit}
                        render={() => (
                            <>
                                <Stack direction={'row'} spacing={3}>
                                    <Grid container>
                                        <Grid
                                            item
                                            container
                                            direction='column'
                                            alignItems='center'
                                            sx={{
                                                border: '1px solid gray',
                                                borderRadius: '2px',
                                                padding: 2,
                                                borderStyle: 'dotted',
                                            }}
                                        >
                                            <UploadFile />
                                            <Typography>
                                                (
                                                {acceptTypeMessage ??
                                                    t(
                                                        'message.pleaseSelectCSVType',
                                                    )}
                                                )
                                            </Typography>
                                            <Typography
                                                sx={{ padding: '10px 0' }}
                                            >
                                                {fileName && `(${fileName})`}
                                            </Typography>
                                            <Field>
                                                {(props) => {
                                                    const handleFileChange = (
                                                        event,
                                                    ) => {
                                                        setFileName(
                                                            event.target
                                                                .files[0]
                                                                ?.name || '',
                                                        )
                                                        changeHandler(event)
                                                    }

                                                    return (
                                                        <Box>
                                                            <input
                                                                id='file-import-popup'
                                                                name='file'
                                                                type='file'
                                                                style={{
                                                                    display:
                                                                        'none',
                                                                }}
                                                                onChange={
                                                                    handleFileChange
                                                                }
                                                                accept={
                                                                    acceptType ??
                                                                    '.csv'
                                                                }
                                                            />
                                                            <label htmlFor='file-import-popup'>
                                                                <Button
                                                                    variant='contained'
                                                                    size='small'
                                                                    component='span'
                                                                    sx={{
                                                                        textTransform:
                                                                            'none',
                                                                        backgroundColor:
                                                                            '#76B72A',
                                                                        '&:hover':
                                                                            {
                                                                                backgroundColor:
                                                                                    '#102F44',
                                                                                color: 'white',
                                                                            },
                                                                    }}
                                                                >
                                                                    {t(
                                                                        'button.browse',
                                                                    )}
                                                                </Button>
                                                            </label>
                                                        </Box>
                                                    )
                                                }}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </Stack>
                                <Stack
                                    direction='row-reverse'
                                    sx={{ marginTop: 1 }}
                                >
                                    <SubmitButton
                                        isLoading={isLoading}
                                        handleSubmit={onSubmit}
                                        name={`${t('button.import')}`}
                                    />
                                </Stack>
                                {((failures && failures.length > 0) ||
                                    (error && error.length > 0)) && (
                                    <Stack direction='row' spacing={3}>
                                        <Grid item xs={12}>
                                            {failures &&
                                                failures.length > 0 && (
                                                    <ErrorBox>
                                                        {failures.map(
                                                            (index) => (
                                                                <ErrorTypography
                                                                    key={
                                                                        index.row
                                                                    }
                                                                >
                                                                    (Row:{' '}
                                                                    {index.row}){' '}
                                                                    {index.contents.map(
                                                                        (
                                                                            content,
                                                                        ) =>
                                                                            content,
                                                                    )}
                                                                </ErrorTypography>
                                                            ),
                                                        )}
                                                    </ErrorBox>
                                                )}
                                            {error && (
                                                <ErrorBox>
                                                    <ErrorTypography>
                                                        {error}
                                                    </ErrorTypography>
                                                </ErrorBox>
                                            )}
                                        </Grid>
                                    </Stack>
                                )}
                            </>
                        )}
                    />
                </Box>
            </StyledModal>
        </>
    )
}
UploadPopup.propTypes = {
    openUpload: PropTypes.bool,
    setOpenUpload: PropTypes.func,
    actionImport: PropTypes.func,
    isLoading: PropTypes.bool,
    acceptType: PropTypes.string,
    acceptTypeMessage: PropTypes.string,
}

export default UploadPopup
